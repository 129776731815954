import React from "react";
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CustomErrorMessage from "../inputsWithRef/customErrorMessage";

interface GenericControllerWithInputProps<T extends FieldValues> {
  //   control: Control<FieldValues> | undefined;
  control: Control<T>;
  name: keyof T;
  rules?: RegisterOptions;
  renderInput: (props: any) => React.ReactElement;
  defaultValue?: keyof T | any;
  convertCurrency?: boolean;
}

const GenericControllerWithInput = React.forwardRef<
  HTMLInputElement,
  GenericControllerWithInputProps<any>
>(
  (
    {
      control,
      name,
      rules,
      renderInput,
      defaultValue = null,
    }: GenericControllerWithInputProps<any>,
    ref
  ) => {
    return (
      <Controller
        name={name as string}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, formState: { errors } }) => (
          <>
            {renderInput({
              ...field,
              ref,
            })}
            <div
              style={{
                visibility: errors[name as string] ? "visible" : "hidden",
                minHeight: "1.75rem",
              }}
            >
              <ErrorMessage
                errors={errors}
                name={name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          </>
        )}
      />
    );
  }
);
GenericControllerWithInput.displayName = "DropdownSubjectSearchWithController";

export default GenericControllerWithInput;
