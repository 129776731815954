import { FC } from "react";
import Restricted from "../../consumer/restricted";
import ActionChainMainTable from "../../components/actionChain/actionChainMainTable";

const ActionChainList: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_action_chains">
      <ActionChainMainTable />
    </Restricted>
  );
};

export default ActionChainList;
