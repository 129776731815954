import { FC, useEffect, useState } from "react";
import { DotLoader } from "react-spinners";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import { IAppointment } from "../../types/Entities";
import { api } from "../../helper/api";
import { Box, Grid, Paper, ListItem, ListItemText } from "@mui/material";
import AppointmentTreeItemFileList from "./appointmentTreeItemFileList";
import AppointmentTreeItemHeaderbar from "./appointmentTreeItemHeaderbar";
import AppointmentTreeItemSubjectInformation from "./appointmentTreeItemSubjectInformation";
import AppointmentTreeItemWidgets from "./appointmentTreeItemWidgets";
import AppointmentTreeItemUploadButton from "./appointmentTreeItemUploadButton";
import AppointmentTreeItemForms from "./appointmentTreeItemForms";
import styles from "./appointmentTreeView.module.css";
import DocumentTable from "../tables/simpleTableViews/documentTable";
import { useParams } from "react-router-dom";
import AppointmentTreeForm from "./appointmentTreeForm";

interface Props {
  appointmentIdProp: string;
}
export interface categoryExaminationSet {
  id?: string;
  status?: string;
  appointment?: string;
  category?: string;
  examination?: string;
  category_name?: string;
  examination_name?: string;
  findings?: [];
}
export interface formValues {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  unit?: string;
  name?: string;
  value?: number;
  appointment?: string;
  findings?: [];
}

const AppointmentTreeView: FC<Props> = ({ appointmentIdProp }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const [currentAppointment, setCurrentAppointment]: any =
    useState<IAppointment>();

  const [executedBy, setExecutedBy] = useState<any>("");
  const [executedByOptions, setExecutedByOptions] = useState<any>([]);
  const [subjectOfAppointment, setSubjectOfAppointment] = useState<any>();
  const [widgets, setWidgets] = useState<any[]>([]);
  const [associatedForms, setAssociatedForms] = useState<any[]>([]);
  const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);

  const { formId, findingId } = useParams() as {
    formId: string;
    findingId: string;
  };

  useEffect(() => {
    if ((appointmentIdProp && appointmentIdProp !== "") || reloadTrigger) {
      /*eslint-disable */
      loadAppointment(appointmentIdProp, (responseData: any) => {
        /*eslint-enable */
      });
    } else {
      setCurrentAppointment();
    }
  }, [appointmentIdProp, formId, reloadTrigger]);

  const loadAppointment = (
    appointmentId: any,
    onSuccessHandler: any = undefined
  ) => {
    setIsLoading(true);
    const additionalRouteParts: any = {};
    additionalRouteParts["dashboard"] = "/";

    api.genericApiRequest({
      entity: "appointment",
      method: "get",
      entityId: appointmentId,
      additionalRouteParts: additionalRouteParts,
      successHandler: (res: any) => {
        const currentAppointmentObject = res.data;
        setCurrentAppointment(currentAppointmentObject);
        setExecutedBy(currentAppointmentObject.executed_by?.id);
        setSubjectOfAppointment(currentAppointmentObject?.subject);
        setWidgets(res.data.widgets);
        setAssociatedForms(res.data.associated_forms);

        if (onSuccessHandler) {
          onSuccessHandler(currentAppointmentObject);
        }
        const additionalUserParameters: any = {};
        additionalUserParameters["impersonate_subject"] =
          "11111111-1111-1111-1111-444444444444";
        additionalUserParameters["is_active"] = "true";

        api.genericApiRequest({
          entity: "user",
          method: "get",
          parametersToRender: {
            additionalUrlParameters: additionalUserParameters,
            depth: "0",
          },

          successHandler: (res: any) => {
            const tempUsers = [];
            for (const key in res.data.results) {
              const item = res.data.results[key];
              const allUsers = {
                value: item.id,
                label: item.last_name + ", " + item.first_name,
              };
              tempUsers.push(allUsers);
            }
            setExecutedByOptions(
              tempUsers.sort((a: any, b: any) => a.label.localeCompare(b.label))
            );
          },
          failHandler: (error: any) => {
            setError(error);
          },
        });
        setIsLoading(false);
        setReloadTrigger(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const handleLoading = (loading: boolean) => {
    setIsLoading(loading);
  };
  const handleSetError = (error: any) => {
    setError(error);
  };
  const handleSetCurrentAppointment = (appointment: any) => {
    setCurrentAppointment(appointment);
  };

  const handleExecutedByChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setExecutedBy(selectedOption.value);
      if (selectedOption.value !== currentAppointment?.executed_by?.id) {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const result = { executed_by: selectedOption?.value };
        api.genericApiRequest({
          entity: "appointment",
          method: "put",
          // successHandler: () => {},
          failHandler: (error: any) => {
            setError(error);
          },
          entityId: String(appointmentIdProp),
          submitData: result,
          config: config,
        });
      }
    }
  };

  const renderErrorMessage = (error: any) => {
    if (error?.finding_set) {
      return error.finding_set.map((finding: any, index: number) => (
        <ListItem key={`finding-${index}`} className={styles.noPadding}>
          <ListItemText
            primary={t("appointment_error_message_incomplete_forms")}
            secondary={
              <>
                <br />
                {finding.form_names.map((form: string, idx: number) => (
                  <span key={`form-${idx}`} className={styles.blockDisplay}>
                    • {form}
                  </span>
                ))}
                <br />
                {t("fill_required_fields_and_try_again")}
              </>
            }
          />
        </ListItem>
      ));
    }

    return error;
  };

  const handleReloadTrigger = () => {
    setReloadTrigger(true);
  };

  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <Box>
          {/* Header  */}
          <Box className={styles.headerBox}>
            <AppointmentTreeItemHeaderbar
              appointment={currentAppointment}
              appointmentId={appointmentIdProp}
              handleSetLoading={handleLoading}
              handleSetError={handleSetError}
              handleSetCurrentAppointment={handleSetCurrentAppointment}
              executedByOptions={executedByOptions}
              executedByPresetValue={executedBy}
              handleExecutedByChange={handleExecutedByChange}
              formId={formId}
              findingId={findingId}
            />
          </Box>

          {formId === undefined && findingId === undefined ? (
            <>
              <Grid container columnSpacing={"1rem"}>
                {/* Left Column */}
                <Grid container item xs={9} className={styles.leftColumn}>
                  <AppointmentTreeItemSubjectInformation
                    appointment={currentAppointment}
                  />
                  <AppointmentTreeItemWidgets widgets={widgets} />
                  <AppointmentTreeItemFileList
                    appointment={currentAppointment}
                  />
                </Grid>

                {/* Right Column */}
                <Grid item xs={3}>
                  <Box>
                    {(!subjectOfAppointment?.is_registered ||
                      (subjectOfAppointment?.is_analog &&
                        !subjectOfAppointment?.is_registered)) && (
                      <Grid item xs={12}>
                        <AppointmentTreeItemUploadButton
                          margin="0.625"
                          useCase="formOverview"
                          subject={subjectOfAppointment}
                          handleTriggerReload={handleReloadTrigger}
                        />
                      </Grid>
                    )}

                    <AppointmentTreeItemForms
                      formsToShow="visible"
                      forms={associatedForms}
                      appointmentId={currentAppointment.id}
                    />
                    <AppointmentTreeItemForms
                      formsToShow="hidden"
                      forms={associatedForms}
                      appointmentId={currentAppointment.id}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Paper className={styles.paperContainer}>
                <h2 className={styles.paperTitle}>{t("all_documents")}</h2>
                <DocumentTable
                  customPageSize={5}
                  folder="all"
                  subjectId={subjectOfAppointment.id}
                  subjectForDispatch={subjectOfAppointment}
                  customDensity="compact"
                  tableMargin={"0"}
                  tableBoxShadow={"none"}
                  buttonLinkToDocuments={true}
                />
              </Paper>
            </>
          ) : (
            <Grid container columnSpacing={"1rem"} rowSpacing={"1rem"}>
              <Grid container item xs={12} className={styles.leftColumn}>
                <AppointmentTreeItemSubjectInformation
                  appointment={currentAppointment}
                />
              </Grid>
              <Grid item xs={12} className={styles.leftColumn}>
                <AppointmentTreeForm />
              </Grid>
            </Grid>
          )}
        </Box>
      )}

      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={renderErrorMessage(error)}
          onClosehandler={() => {
            setError("");
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
};

export default AppointmentTreeView;
