import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../helper/api";
import {
  Controls,
  Edge,
  MarkerType,
  MiniMap,
  ReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";
import {
  CustomNode,
  transformResponseToConnectionState,
  transfromResponseToActionState,
} from "./actionChainCreateAndEdit";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import ActionChainCustomNode from "./actionChainCustomNode";
import ActionChainCustomConnection from "./actionChainCustomConnection";
import styles from "./actionChains.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { IconButton, Tooltip } from "@mui/material";

const ActionChainProgress: FC = () => {
  const [action, setAction] = useState<CustomNode[]>([]);
  const [connection, setConnection] = useState<Edge[]>([]);
  const [templateName, setTemplateName] = useState<string>("");
  const [subjectName, setSubjectName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const { actionChainTemplateId, actionChainProgressId } = useParams() as {
    actionChainTemplateId: string;
    actionChainProgressId: string;
  };
  const navigate = useNavigate();
  const nodesWithDeleteHandlerWidth = "300px";
  const nodesWithDeleteHandlerHeight = "95px";
  const companyProgressNodeWidth = "150px";
  const companyProgressNodeHeight = "";

  useEffect(() => {
    getActionChainTemplateItemsById(
      actionChainTemplateId,
      setAction,
      setConnection,
      setTemplateName,
      setError
    );
    getActionChainProgressItemsById(
      actionChainProgressId,
      setAction,
      setConnection,
      setSubjectName,
      setIsLoading,
      setError
    );
  }, [actionChainTemplateId, actionChainProgressId]);

  const customNodes = action
    .filter((node) => node.actionChainItem?.metadata.submitMethod !== "delete")
    .map((node) => ({
      ...node,
      type: "companyProgress",
      data: {
        ...node.data,
      },
    }));

  const customConnection = connection.map((con) => ({
    ...con,
    type: "companyProgress",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },

    data: {
      ...con.data,
    },
  }));
  const nodeTypes = useMemo(
    () => ({
      withDeleteHandler: (props: any) => (
        <ActionChainCustomNode
          {...props}
          nodeWidth={nodesWithDeleteHandlerWidth}
          nodeHeight={nodesWithDeleteHandlerHeight}
        />
      ),
      companyProgress: (props: any) => (
        <ActionChainCustomNode
          {...props}
          nodeWidth={companyProgressNodeWidth}
          nodeHeight={companyProgressNodeHeight}
          fontSize="12px"
        />
      ),
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      withDeleteHandler: (props: any) => (
        <ActionChainCustomConnection {...props} style={{ color: "red" }} />
      ),
      companyProgress: (props: any) => (
        <ActionChainCustomConnection {...props} style={{ color: "red" }} />
      ),
    }),
    []
  );

  const handleNavigateBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className={styles.heading_wrapper}>
        <Tooltip placement="right" title={t("back")}>
          <IconButton
            className={styles.backButton}
            onClick={handleNavigateBack}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <h1 className={styles.heading}>
          {templateName} - {subjectName}
        </h1>
      </div>
      <ReactFlowProvider>
        {!isLoading && (
          <ReactFlow
            nodes={customNodes}
            nodeTypes={nodeTypes}
            edges={customConnection}
            edgeTypes={edgeTypes}
            snapToGrid={true}
            snapGrid={[15, 15]}
            fitView
          >
            <Controls />
            <MiniMap />
          </ReactFlow>
        )}
      </ReactFlowProvider>
      {error && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default ActionChainProgress;

function getActionChainTemplateItemsById(
  actionChainTemplateId: string,
  setAction: any,
  setConnection: any,
  setTemplateName: any,
  setError: any
) {
  const additionnal: any = {};
  additionnal["action_chain_template"] = actionChainTemplateId;
  api.genericApiRequest({
    method: "get",
    entity: "actionChainItemTemplate",
    parametersToRender: { additionalUrlParameters: additionnal },
    successHandler: (res: any) => {
      const data = res.data.results;
      if (!res.data.results || res.data.results.length === 0) {
        return;
      }

      const tempActionStateData = transfromResponseToActionState(data);
      const tempConnectionStateData = transformResponseToConnectionState(data);

      setAction(tempActionStateData);
      setConnection(tempConnectionStateData);
      setTemplateName(data?.[0].action_chain_template?.name);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}
function getActionChainProgressItemsById(
  actionChainProgressId: string,
  setAction: any,
  setConnection: any,
  setSubjectName: any,
  setIsLoading: any,
  setError: any
) {
  const additionnal: any = {};
  additionnal["action_chain_progress"] = actionChainProgressId;
  api.genericApiRequest({
    method: "get",
    entity: "actionChainItemProgress",
    parametersToRender: { additionalUrlParameters: additionnal },
    successHandler: (res: any) => {
      const progressData = res.data.results;
      setSubjectName(progressData?.[0].action_chain_progress?.subject?.name);

      setAction((prevActions: CustomNode[]) =>
        mergeProgressIntoActions(prevActions, progressData)
      );
      setConnection((prevConnections: Edge[]) =>
        mergeProgressIntoConnections(prevConnections, progressData)
      );
      setIsLoading(false);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}

function mergeProgressIntoActions(
  actions: CustomNode[],
  progressData: any[]
): CustomNode[] {
  const progressMap = new Map(
    progressData.map((item) => [
      item.action_chain_item_template.id,
      item.status,
    ])
  );

  return actions.map((action) => {
    const progressStatus = progressMap.get(action.id);
    if (progressStatus) {
      return {
        ...action,
        data: {
          ...action.data,
          status: progressStatus,
        },
      };
    }
    return action;
  });
}
function mergeProgressIntoConnections(
  connections: Edge[],
  progressData: any[]
): Edge[] {
  const progressMap = new Map(
    progressData.map((item) => [
      item.action_chain_item_template.id,
      item.status,
    ])
  );

  return connections.map((connection: any) => {
    const targetId = connection?.data?.targetData?.id;

    if (targetId && progressMap.has(targetId)) {
      return {
        ...connection,
        data: {
          ...connection.data,
          targetData: {
            ...connection.data.targetData,
            status: progressMap.get(targetId),
          },
        },
      };
    }

    return connection;
  });
}
