import { FC } from "react";
import { Handle, Position, NodeProps } from "@xyflow/react";
import { IconButton } from "@mui/material";
import styles from "./actionChains.module.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import classNames from "classnames";

interface CustomNodeProps extends NodeProps {
  data: {
    label: string;
    onDelete?: (id: string) => void;
    onOpenModal: (open: boolean, id: string, data: any) => void;
    action?: string;
    itemId?: string;
    additional_data?: string;
    id?: string;
    status?: any;
    is_entrypoint?: any;
  };
  nodeWidth?: string;
  nodeHeight?: string;
  fontSize?: string;
}

const ActionChainCustomNode: FC<CustomNodeProps> = ({
  id,
  data,
  type,
  nodeWidth,
  nodeHeight,
  fontSize,
}) => {
  const companyProgressWrapperClassnames = classNames(
    styles.companyProgressWrapper,
    {
      [styles.status_open]: data.status === "open" || !data.status,
      [styles.status_inprogress]: data.status === "in_progress",
      [styles.status_completed]: data.status === "completed",
      [styles.is_entrypoint]: data.is_entrypoint === true,
    }
  );
  const withDeleteHandlerWrapperClassnames = classNames(
    styles.withDeleteHandlerWrapper,
    {
      [styles.is_entrypoint]: data.is_entrypoint === true,
    }
  );

  const renderCustomNode = () => {
    switch (type) {
      case "withDeleteHandler":
        return (
          <div
            className={withDeleteHandlerWrapperClassnames}
            style={{ height: nodeHeight || "auto", width: nodeWidth || "auto" }}
            onClick={() =>
              !data.is_entrypoint && data.onOpenModal(true, id, data)
            }
          >
            {!data.is_entrypoint && (
              <IconButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  data?.onDelete?.(id);
                }}
                color="error"
                className={styles.customNodeDeleteButton}
                sx={{
                  position: "absolute",
                  top: -30,
                  right: -25,
                  cursor: "pointer",
                  background: "#fff",
                  padding: "0",
                }}
              >
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            )}

            <div>{data.label}</div>
            <div className={styles.actionLabel}> {data.action}</div>
            {!data.is_entrypoint && (
              <Handle
                type="target"
                position={Position.Top}
                className={styles.nodeHandle}
              />
            )}
            <Handle
              type="source"
              position={Position.Bottom}
              className={styles.nodeHandle}
            />
          </div>
        );
        break;
      case "companyProgress":
        return (
          <div
            className={companyProgressWrapperClassnames}
            style={{ height: nodeHeight || "auto", width: nodeWidth || "auto" }}
          >
            <div style={{ fontSize: fontSize }}>{data.label}</div>
            {!data.is_entrypoint && (
              <Handle
                type="target"
                position={Position.Top}
                className={styles.nodeHandle_progress}
                isConnectable={false}
              />
            )}
            <Handle
              type="source"
              position={Position.Bottom}
              className={styles.nodeHandle_progress}
              isConnectable={false}
            />
          </div>
        );
    }
  };
  return <>{renderCustomNode()}</>;
};

export default ActionChainCustomNode;
