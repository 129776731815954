import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../helper/api";
import dayjs from "dayjs";
import GeneralListItem from "../general/generaListItem";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { t } from "i18next";
import { DotLoader } from "react-spinners";
import TimeSlotItem from "./timeSlotItem";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import Modal from "../modal";
import { DateTimePicker } from "@mui/x-date-pickers";
import GenericNotification from "../notification/genericNotification";
import styles from "./timeSlotsEdit.module.css";
import GenericButton from "../forms/inputs/button/genericButton";

const TimeSlotsEdit: FC = () => {
  const [appointmentRequest, setAppointmentRequest] = useState<any>([]);
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeslotsForDay, setTimeslotsForDay] = useState<any>([]);

  const [openAddTimeSlotModal, setOpenAddTimeSlotModal] =
    useState<boolean>(false);
  const [error, setError] = useState<any>();

  const [newTimeSlotStartDate, setNewTimeSlotStartDate] = useState<any>(
    dayjs(new Date())
  );
  const [newtimeSlotEndDate, setNewTimeSlotEndDate] = useState<any>(
    dayjs(new Date())
  );
  const [slotduration, setSlotDuration] = useState<number>(1);
  const [numberOfNewSlots, setNumberOfNewSlots] = useState<number>(1);
  const [allSlotData, setAllSlotData] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const { appointmentrequestId } = useParams() as {
    appointmentrequestId: string;
  };
  const navigate = useNavigate();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  useEffect(() => {
    getAppointmentSlots();
  }, []);

  const getAppointmentSlots = () => {
    let itemCollection: any = [];
    const additionalUrlParameters: any = {};

    additionalUrlParameters["appointment_request"] = appointmentrequestId;
    additionalUrlParameters["include_deleted"] = "true";
    additionalUrlParameters["depth"] = "0";
    additionalUrlParameters["limit"] = "50";

    api.genericApiRequest({
      entity: "appointmentSlot",
      method: "get",
      getAllPages: true,
      parametersToRender: additionalUrlParameters,
      successHandler: (res: any) => {
        itemCollection = res.data.results;
        const allSlotData: any = {};
        for (const key in itemCollection) {
          const item = itemCollection[key];
          allSlotData[item.id] = item;
        }
        setAllSlotData(allSlotData);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  useEffect(() => {
    const allDates: any = {};

    for (const key in allSlotData) {
      const item = allSlotData[key];
      const startDate = dayjs(item.start_date).format("DD.MM.YYYY");
      if (!allDates[startDate]) {
        allDates[startDate] = [];
      }

      allDates[startDate].push(item);
    }

    setAppointmentRequest(allDates);
    setIsLoading(false);
  }, [allSlotData]);

  useEffect(() => {
    updateSlotItemsForSelectedDay();
  }, [selectedDay, appointmentRequest]);

  useEffect(() => {
    if (openAddTimeSlotModal) {
      const calculatedEndtime = new Date(
        newTimeSlotStartDate.$d.getTime() +
          slotduration * 60 * 1000 * numberOfNewSlots
      );
      setNewTimeSlotEndDate(dayjs(calculatedEndtime));
    }
  }, [
    slotduration,
    numberOfNewSlots,
    newTimeSlotStartDate,
    openAddTimeSlotModal,
  ]);

  const updateSlotItemsForSelectedDay = () => {
    let timeSlotStartDateForNewSlots = undefined;
    let slotDurationForNewSlots = undefined;

    if (
      selectedDay !== "" &&
      selectedDay in appointmentRequest &&
      appointmentRequest[selectedDay] !== undefined
    ) {
      const sortedSlotsForSelectedDay = appointmentRequest[selectedDay].sort(
        (a: any, b: any) => a.start_date.localeCompare(b.start_date)
      );

      const slotsCount = sortedSlotsForSelectedDay.length;
      let counter = 0;

      for (const key in sortedSlotsForSelectedDay) {
        counter++;

        if (counter === slotsCount) {
          slotDurationForNewSlots = sortedSlotsForSelectedDay[key].duration;
          timeSlotStartDateForNewSlots = dayjs(
            sortedSlotsForSelectedDay[key].start_date
          ).add(slotDurationForNewSlots, "minutes");
        }
      }

      setNewTimeSlotStartDate(timeSlotStartDateForNewSlots);
      setSlotDuration(slotDurationForNewSlots);
      setTimeslotsForDay(sortedSlotsForSelectedDay);
    }
  };

  const handleSelectDay = (day: any) => {
    setSelectedDay(day);
  };

  const handleSelectNewTimeSlotDay = (day: any) => {
    setNewTimeSlotStartDate(dayjs(day));
    setNewTimeSlotEndDate(dayjs(day));
  };

  const handleSlotDurationChange = (event: any) => {
    setSlotDuration(parseInt(event.target.value));
  };
  const handleNumberOfNewSlots = (event: any) => {
    setNumberOfNewSlots(parseInt(event.target.value));
  };

  const handleNavigateBack = () => {
    navigate("/appointmentrequest");
  };

  const handleSubmitNewTimeslots = async () => {
    const newEndDate = new Date(
      newTimeSlotStartDate.$d.getTime() + slotduration * 60000
    );
    const result = {
      appointment_request: appointmentrequestId,
      duration: slotduration,
      start_date: newTimeSlotStartDate.$d.toISOString(),
      end_date: newEndDate.toISOString(),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const promises = [];
      let startTime = newTimeSlotStartDate.$d;
      for (let i = 0; i < numberOfNewSlots; i++) {
        const endTime = new Date(startTime);
        endTime.setMinutes(endTime.getMinutes() + result.duration);
        const newResult = {
          ...result,
          start_date: startTime,
          end_date: endTime,
        };
        promises.push(
          new Promise((resolve) => {
            api.genericApiRequest({
              entity: "appointmentSlot",
              method: "post",
              submitData: newResult,
              config: config,

              successHandler: (res: any) => {
                resolve(res.data);
              },
              failHandler: (error: any) => {
                let tempError = error;
                if (error.detail) {
                  tempError = {
                    "": [t("error_occurred")],
                  };
                }
                resolve({ error: tempError, status: "false" });
              },
            });
          })
        );
        startTime = endTime;
      }
      await Promise.all(promises).then((promiseResults: any) => {
        const failedPosts = [];
        const newSlots: any = {};

        for (const promiseResultItem of promiseResults) {
          if (
            promiseResultItem.status &&
            promiseResultItem.status === "false"
          ) {
            failedPosts.push(promiseResultItem.error);
          } else {
            newSlots[promiseResultItem.id] = promiseResultItem;
          }
        }

        setAllSlotData(Object.assign({}, allSlotData, newSlots));
        if (failedPosts.length !== 0) {
          const combinedErrors = failedPosts.reduce((acc, obj) => {
            const key = Object.keys(obj)[0];
            acc[key] = obj[key];
            return acc;
          }, {});

          setError(combinedErrors);
        } else {
          setIsLoading(false);
          setOpenAddTimeSlotModal(false);

          setNotificationVariant("success");
          setNotificationMessage(
            promiseResults.length > 1
              ? t("slot_successful_added_multi")
              : t("slot_successful_added")
          );
          setShowNotification(true);
        }
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleToggleDelete = (
    slot: any,
    deleted: boolean,
    successCallbackOfSlotButton: any
  ) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const result = {
      start_date: slot.start_date,
      end_date: slot.end_date,
      duration: slot.duration,
      appointment_request: slot.appointment_request.id,
      deleted: deleted ? false : true,
    };
    api.genericApiRequest({
      method: "put",
      entity: "appointmentSlot",
      entityId: slot.id,
      submitData: result,
      config: config,
      successHandler: (res: any) => {
        allSlotData[slot.id].deleted = res.data.deleted;
        setAllSlotData(allSlotData);
        successCallbackOfSlotButton();
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const handleAllSlots = async (disableAll: boolean) => {
    setIsLoading(true);

    const promises = [];
    for (const slot of timeslotsForDay) {
      promises.push(
        new Promise((resolve) => {
          handleToggleDelete(slot, !disableAll, () => {
            resolve(true);
          });
        })
      );
    }

    await Promise.all(promises).then(() => {
      updateSlotItemsForSelectedDay();
      setIsLoading(false);
    });
  };

  const renderedDaysButtons = [];
  const renderedSlotItemsForSelectedDay = [];

  const sortedDays = Object.keys(appointmentRequest).sort(
    (a: any, b: any) =>
      dayjs(a, "DD.MM.YYYY").toDate().getTime() -
      dayjs(b, "DD.MM.YYYY").toDate().getTime()
  );

  for (const key in sortedDays) {
    const day = sortedDays[key];
    renderedDaysButtons.push(
      <Grid item key={day} onClick={() => handleSelectDay(day)}>
        <GeneralListItem
          allowHover={true}
          hideButton={true}
          wrapperClassName={`
            ${styles.generalListItemWrapper}
            ${
              selectedDay === day
                ? styles.generalListItemWrapper__selectedDay
                : styles.generalListItemWrapper__noSelectedDay
            }
          `}
        >
          {day}
        </GeneralListItem>
      </Grid>
    );
  }

  if (selectedDay !== "" && appointmentRequest[selectedDay] !== undefined) {
    const slots = appointmentRequest[selectedDay].sort((a: any, b: any) =>
      a.start_date.localeCompare(b.start_date)
    );

    for (const key in slots) {
      const slot = slots[key];
      const slotId = slot.id;

      renderedSlotItemsForSelectedDay.push(
        <TimeSlotItem
          key={slotId}
          slotData={slot}
          handleToggleDelete={handleToggleDelete}
        />
      );
    }
  }

  let spinner = <></>;

  const handleCloseNewTimeSlotModal = () => {
    setOpenAddTimeSlotModal(false);
    setNumberOfNewSlots(1);
  };

  if (isLoading) {
    spinner = (
      <div className={styles.spinnerContainer}>
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      </div>
    );
  }

  return (
    <>
      {spinner}
      <Tooltip placement="right" title={t("back")}>
        <IconButton
          onClick={handleNavigateBack}
          className={styles.navigateBackButton}
        >
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Grid container columnSpacing={"1rem"} className={styles.container}>
        <h1>{t("edit_available_timeslots")}</h1>
        <Grid item xs={12}>
          <div className={styles.slotButtonsContainer}>
            <h2>{t("select_date")}</h2>
          </div>
        </Grid>
        {renderedDaysButtons}
        {selectedDay !== "" && (
          <Grid item container xs={12} columnSpacing={"1rem"}>
            <Grid item xs={12}>
              <div className={styles.slotButtonsContainer}>
                <h2>{t("select_timeslot")}</h2>
                {selectedDay !== "" && (
                  <>
                    <GenericButton
                      variant="outlined"
                      color="error"
                      onClick={() => handleAllSlots(true)}
                      className={styles.buttonStyle}
                    >
                      {t("deactivate_all_slots")}
                    </GenericButton>
                    <GenericButton
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        handleAllSlots(false);
                      }}
                      className={styles.buttonStyle}
                    >
                      {t("activate_all_slots")}
                    </GenericButton>
                    <GenericButton
                      variant="outlined"
                      color="tertiary"
                      onClick={() => {
                        setOpenAddTimeSlotModal(true);
                      }}
                      className={styles.buttonStyle}
                    >
                      {t("add_slot")}
                    </GenericButton>
                  </>
                )}
              </div>
            </Grid>
            {renderedSlotItemsForSelectedDay}
          </Grid>
        )}
      </Grid>

      {openAddTimeSlotModal && (
        <Modal
          open={openAddTimeSlotModal}
          onClose={handleCloseNewTimeSlotModal}
          contentStyle={{ paddingTop: "1rem !important" }}
          onSubmit={handleSubmitNewTimeslots}
          title={t("add_slot")}
        >
          <Grid container rowSpacing={"1rem"} columnSpacing={"1rem"}>
            <Grid item xs={6}>
              <DateTimePicker
                label={t("start_time")}
                value={newTimeSlotStartDate}
                onChange={(date: any) => handleSelectNewTimeSlotDay(date)}
                className={styles.dateTimePicker}
              />
            </Grid>
            {slotduration !== undefined && (
              <Grid
                container
                item
                xs={12}
                columnSpacing={"1rem"}
                rowSpacing={"1rem"}
              >
                <Grid item xs={6}>
                  <TextField
                    label={t("duration")}
                    type="number"
                    value={slotduration}
                    onChange={handleSlotDurationChange}
                    className={styles.textField}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label={t("number_slots")}
                    type="number"
                    value={numberOfNewSlots}
                    onChange={handleNumberOfNewSlots}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker
                    label={t("end_time")}
                    value={newtimeSlotEndDate}
                    // onChange={(date: any) => handleSelectNewTimeSlotDay(date)}
                    className={styles.dateTimePicker}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Modal>
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default TimeSlotsEdit;
