import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { FC, ReactNode, useEffect, useMemo, useState, Fragment } from "react";
import { IFileItem } from "../../types/FileItem";
import FileTableIcon from "./fileTableIcons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DownloadIcon from "@mui/icons-material/Download";
import "./documents.css";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { t } from "i18next";
import { api } from "../../helper/api";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import dayjs from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styles from "./fileTable.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

interface Props {
  openCreateModal?: any;
  deleteFile?: any;
  openMoveFileModal?: any;
  moveFileSource?: any;
  markAsReviewed?: any;
  markAsUnReviewed?: any;
  folderId?: any;
  subjectId?: any;
  uploadSuccess?: boolean;
  handleUploadReset?: any;
  setVisible?: any;
  setInvisible?: any;
  setLocked?: any;
  setUnlocked?: any;
  downloadFile?: any;
  customColumns?: any;
  showRowActions: boolean;
  customPageSize?: any;
  customDensity?: "spacious" | "compact" | "comfortable";
  customTopToolBarAction?: ReactNode | undefined;
  tableMargin?: any;
  rowSelection?: boolean;
  subdomain?: any;
  allowColumnFiltering?: boolean;
  showColumnFilterbyDefault?: boolean;
  tableBoxShadow?: any;
}

const FileTable: FC<Props> = (props: Props) => {
  const [loadMoreDataTriggerEl, setLoadMoreDataTriggerEl] =
    useState<HTMLElement | null>(null);

  const [showLoadingMoreSpinner, setShowLoadingMoreSpinner] = useState(false);
  const [usePagination, setUsePagination] = useState(true);
  const [paginationModeToggled, setPaginationModeToggled] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: props.customPageSize ? props.customPageSize : 20,
  });

  const [fileData, setFileData] = useState<IFileItem[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [rowCount, setRowCount] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [error, setError] = useState<any>();

  const [isPageAtTop, setIsPageAtTop] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [disableDeleteAllButton, setDisableDeleteAllButton] =
    useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  const columnSorting = "-created_on";

  const children = useSelector((state: RootState) => state.parent);
  const rootOutletContentContainer =
    document.getElementById("rootOutletContent");

  const columnVisibility = {
    id: false,
    name: true,
    size: false,
    visible_to_subject: false,
    locked: false,
    created_on: false,
    extension: false,
    modified_on: false,
    created_by: false,
    modified_by: false,
  };

  const tempColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        id: "id",
        enableHiding: props.subdomain !== "admin" ? false : true,
        visibleInShowHideMenu: props.subdomain !== "admin" ? false : true,
      },

      {
        header: t("name"),
        accessorKey: "name",
        id: "name",
        enableColumnFilter: false,
        size: isMobile ? window.innerWidth * 0.8 : 400,
        Cell: ({ row, renderedCellValue }: any) => {
          return (
            <Box className={styles.fileTableName}>
              <FileTableIcon extension={row.getValue("extension")} />
              <a
                href={previewFile(row)}
                target="_blank"
                rel="noreferrer"
                className="fileTableNameLink"
                onClick={() => markFileAsDownloaded(row.getValue("id"))}
              >
                <Tooltip title={formatFilenameString(renderedCellValue)}>
                  <span
                    className={
                      !row.original.has_been_downloaded
                        ? styles.undownloadedFileBold
                        : undefined
                    }
                  >
                    {formatFilenameString(renderedCellValue)}
                  </span>
                </Tooltip>
              </a>
            </Box>
          );
        },
      },
      {
        header: t("filesize"),
        size: 80,
        enableColumnFiltering: false,
        id: "size",
        accessorKey: "size",
        enableColumnFilter: false,
        Cell: ({ row }: any) => {
          return formatFilesize(row.original.size);
        },
      },
      {
        header: t("status"),
        size: 80,
        id: "visible_to_subject, locked",
        Cell: ({ row }: any) => {
          return (
            <div className={styles.statusIcons}>
              {row.getValue("visible_to_subject") ? (
                <Tooltip title={t("visible")}>
                  <VisibilityIcon color="success" />
                </Tooltip>
              ) : (
                <Tooltip title={t("not_visible")}>
                  <VisibilityOffIcon color="error" />
                </Tooltip>
              )}

              {row.getValue("locked") ? (
                <Tooltip title={t("locked")}>
                  <LockIcon color="error" />
                </Tooltip>
              ) : (
                <Tooltip title={t("not_locked")}>
                  <LockOpenIcon color="success" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        header: t("created_on"),
        accessorKey: "created_on",
        id: "created_on",
        size: 80,
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return (
            <span>
              {subdomain !== "admin"
                ? replaceDate(cell.getValue(), false)
                : replaceDate(cell.getValue())}
            </span>
          );
        },
      },
      {
        header: t("extension"),
        accessorKey: "extension",
        id: "extension",
      },
      {
        header: t("visible"),
        enableHiding: false,
        Header: <VisibilityIcon />,
        accessorKey: "visible_to_subject",
        visibleInShowHideMenu: false,

        id: "visible_to_subject",
        Cell: ({ row }: any) => {
          return (
            <>
              {row.getValue("visible_to_subject") ? (
                <VisibilityIcon color="success" />
              ) : (
                <VisibilityOffIcon color="error" />
              )}
            </>
          );
        },
      },
      {
        header: t("locked"),
        enableHiding: false,
        Header: <LockIcon />,
        accessorKey: "locked",
        visibleInShowHideMenu: false,

        id: "locked",
        Cell: ({ row }: any) => {
          return (
            <>
              {row.getValue("locked") ? (
                <LockIcon color="error" />
              ) : (
                <LockOpenIcon color="success" />
              )}
            </>
          );
        },
      },
    ],
    []
  );
  let columns;
  if (subdomain === "admin") {
    columns = [
      ...tempColumns.slice(0, 5),
      {
        header: t("modified_on"),
        accessorKey: "modified_on",
        id: "modified_on",
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return <span>{replaceDate(cell.getValue())}</span>;
        },
      },
      {
        header: t("created_by"),
        accessorKey: "created_by",
        customColumnFilterId: "created_by_name",

        id: "created_by",
        Cell: ({ cell }: any) => {
          return <span>{cell.getValue().name}</span>;
        },
      },
      {
        header: t("modified_by"),
        accessorKey: "modified_by",
        customColumnFilterId: "modified_by_name",
        id: "modified_by",
        Cell: ({ cell }: any) => {
          return <span>{cell.getValue().name}</span>;
        },
      },
      ...tempColumns.slice(5),
    ];
  } else columns = tempColumns;

  if (props.customColumns) {
    columns = props.customColumns;
  }

  const tableOptions = {
    filters: props.allowColumnFiltering,
    columnHiding:
      subdomain === "admin" || subdomain === "manage" ? true : false,
  };
  const data = fileData;
  const table = useMaterialReactTable({
    data,
    columns,
    state: { rowSelection },
  });

  const handleColumnFilter = (searchValues: any) => {
    setColumnFilter(searchValues);
  };

  useEffect(() => {
    if (
      (!props.folderId && props.moveFileSource.length !== 0) ||
      (!props.folderId && !props.uploadSuccess)
    ) {
      setInitialLoading(true);
    } else if (props.uploadSuccess || props.folderId || props.subjectId) {
      setInitialLoading(false);
    }
  }, [
    props.folderId,
    props.moveFileSource,
    props.uploadSuccess,
    props.subjectId,
    columnFilter,
  ]);

  const fetchData = async (pageIndex?: number) => {
    if (fileData.length === 0) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    const additionalUrlParameters: any = {};
    additionalUrlParameters["folder_id"] = props.folderId;

    if (props.subjectId) {
      additionalUrlParameters["impersonate_subject"] = props.subjectId;
    }
    if (columnFilter.length !== 0) {
      for (const key in columnFilter) {
        const singleColumnValues: any = columnFilter[key];
        const column = singleColumnValues.id;
        let columnSearchValue = singleColumnValues.value;
        const columnDef: any = table.getColumn(column).columnDef;
        let customFilterId = "";
        let filterVariant = "";
        if (columnDef?.customColumnFilterId) {
          customFilterId = columnDef?.customColumnFilterId;
        }
        if (columnDef?.filterVariant) {
          filterVariant = columnDef?.filterVariant;
        }

        if (filterVariant === "date") {
          const tempDateString = new Date(
            singleColumnValues.value
          ).toISOString();
          columnSearchValue = dayjs(tempDateString).format("YYYY-MM-DD");
          additionalUrlParameters[column + "__date"] = columnSearchValue;
        } else if (customFilterId !== "") {
          additionalUrlParameters[customFilterId + "__icontains"] =
            columnSearchValue;
        } else
          additionalUrlParameters[column + "__icontains"] = columnSearchValue;
      }
    }

    if (!pageIndex) {
      pageIndex = pagination.pageIndex;
    }

    try {
      /*eslint-disable */
      const res = await api.genericApiRequest({
        entity: "file",
        method: "get",
        parametersToRender: {
          depth: "0",
          ordering: columnSorting,
          limit: pagination.pageSize,
          offset: pageIndex * pagination.pageSize,
          search: globalFilter,
          additionalUrlParameters: additionalUrlParameters,
        },

        successHandler: (res: any) => {
          /*eslint-enable */
          const data = res.data;
          if (usePagination) {
            setFileData(data.results);
          } else {
            setFileData((prevData) => {
              const newData = data.results.filter(
                (newFile: any) =>
                  !prevData.some((prevFile: any) => prevFile.id === newFile.id)
              );
              return [...prevData, ...newData];
            });
          }
          setRowCount(data.count);
          setIsLoading(false);
          setInitialLoading(true);
          const files = [];
          for (const key in data.results) {
            const item = data.results[key];
            const id = item.id;
            const name = item.name;
            files.push({ id: id, name: name });
          }
          if (props.handleUploadReset) {
            props.handleUploadReset();
          }
        },
      });
    } catch (error) {
      setError(error);
      return;
    }
    setIsRefetching(false);
  };

  useEffect(() => {
    if (!initialLoading) {
      fetchData();
    }
  }, [
    props.folderId,
    props.moveFileSource,
    props.subjectId,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    if (!globalFilter) {
      setIsLoading(true);
      setFileData([]);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      fetchData(0);
      return;
    }

    if (globalFilter.length < 3) {
      return;
    }

    setIsLoading(true);
    setFileData([]);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    fetchData(0);
  }, [globalFilter, columnFilter]);

  const handleLoadMoreData = () => {
    if (isRefetching || fileData.length >= rowCount) {
      return;
    } else if (fileData.length < rowCount) {
      const nextPageIndex = pagination.pageIndex + 1;
      fetchData(nextPageIndex);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: nextPageIndex,
      }));
    }
  };

  const debounce = (func: any, delay: number) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    let observer: IntersectionObserver | null = null;
    const tableContainer = document.querySelector(".MuiTableContainer-root");

    const handleIntersection = debounce(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];
        if (
          entry.isIntersecting &&
          !isLoading &&
          !isRefetching &&
          fileData.length < rowCount
        ) {
          setShowLoadingMoreSpinner(true);
          handleLoadMoreData();
        }
      },
      300
    );

    const handleScroll = debounce(() => {
      if (!usePagination && !isLoading && !isRefetching && tableContainer) {
        const bottomReached =
          tableContainer.scrollHeight - tableContainer.scrollTop <=
          tableContainer.clientHeight + 100;
        const allDataFetched = fileData.length === rowCount;

        if (bottomReached && !allDataFetched) {
          setShowLoadingMoreSpinner(true);
          handleLoadMoreData();
        } else if (allDataFetched) {
          setShowLoadingMoreSpinner(false);
        }
      }
    }, 300);

    if (loadMoreDataTriggerEl && !usePagination) {
      observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.5,
      });
      observer.observe(loadMoreDataTriggerEl);
    }

    if (isMobile && tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (observer && loadMoreDataTriggerEl) {
        observer.unobserve(loadMoreDataTriggerEl);
        observer.disconnect();
        observer = null;
      }
      if (isMobile && tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
      setTimeout(() => {
        setShowLoadingMoreSpinner(false);
      }, 2000);
    };
  }, [
    loadMoreDataTriggerEl,
    isLoading,
    isRefetching,
    usePagination,
    isMobile,
    fileData.length,
    rowCount,
  ]);

  const handlePaginationScrollToggle = () => {
    const fileTablePaginationMode = !usePagination;
    setUsePagination((prev) => !prev);
    setPaginationModeToggled(true);
    localStorage.setItem(
      "savedPaginationMode",
      fileTablePaginationMode ? "paginationMode" : "infiniteScrollMode"
    );
  };

  useEffect(() => {
    const savedPaginationMode = localStorage.getItem("savedPaginationMode");

    if (isMobile) {
      setUsePagination(false);
    } else if (savedPaginationMode === "paginationMode") {
      setUsePagination(true);
    } else {
      setUsePagination(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (paginationModeToggled) {
      setFileData([]);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 0,
      }));
      fetchData(0);
      setPaginationModeToggled(false);
    }
  }, [paginationModeToggled]);

  const scrollToTop = () => {
    if (rootOutletContentContainer) {
      rootOutletContentContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const scrollToBottom = () => {
    if (rootOutletContentContainer) {
      rootOutletContentContainer.scrollTo({
        top: rootOutletContentContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const isElementScrollable = (element: any) => {
      return element && element.scrollHeight > element.clientHeight;
    };

    const checkScrollable = () => {
      if (rootOutletContentContainer) {
        if (isElementScrollable(rootOutletContentContainer) && rowCount !== 0) {
          if (!showScrollButton) {
            setShowScrollButton(true);
          }
        } else {
          if (showScrollButton) {
            setTimeout(() => setShowScrollButton(false), 250);
          }
        }
      }
    };

    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => {
      window.removeEventListener("resize", checkScrollable);
    };
  }, [rowCount, showScrollButton]);

  useEffect(() => {
    const handleScroll = () => {
      if (rootOutletContentContainer) {
        const scrollTop = rootOutletContentContainer.scrollTop;
        const isPageAtTop = scrollTop <= 10;
        setIsPageAtTop(isPageAtTop);
      }
    };
    const debouncedHandleScroll = debounce(handleScroll, 100);

    if (rootOutletContentContainer) {
      rootOutletContentContainer.addEventListener(
        "scroll",
        debouncedHandleScroll
      );
    }

    handleScroll();
    return () => {
      if (rootOutletContentContainer) {
        rootOutletContentContainer.removeEventListener(
          "scroll",
          debouncedHandleScroll
        );
      }
    };
  }, []);

  useEffect(() => {
    const data = table
      .getSelectedRowModel()
      .flatRows.map((item: any) => item.original);
    const hasLockedRows = data.some((item: any) => item.locked);
    setDisableDeleteAllButton(hasLockedRows);
  }, [rowSelection]);

  function formatFilenameString(fileName: any) {
    let validFileName;

    if (typeof fileName === "object") {
      validFileName = fileName.props?.["aria-label"];
    } else if (typeof fileName === "string") {
      validFileName = fileName;
    }
    let stringWithSpaces = validFileName.replace(/_+/g, " ");

    const dateRegex = /(\d{2,4}-\d{1,2}-\d{1,2})$/;
    const match = stringWithSpaces.match(dateRegex);

    if (match) {
      const dateString = match[1];
      const [year, month, day] = dateString.split("-");
      const formatedDate = `${day}.${month}.${year}`;
      stringWithSpaces = stringWithSpaces.replace(dateRegex, formatedDate);
    }
    return stringWithSpaces;
  }

  function formatFilesize(filesize: number) {
    const kiloBytes = 1024;
    const megaBytes = kiloBytes * 1024;

    if (filesize < megaBytes) {
      return (filesize / kiloBytes).toFixed(2) + " KB";
    } else {
      return (filesize / megaBytes).toFixed(2) + " MB";
    }
  }

  const markFileAsDownloaded = (id: any) => {
    if (subdomain !== "admin") {
      const routeParts: any = {};
      routeParts["mark_downloaded"] = "/";
      api.genericApiRequest({
        entity: "file",
        method: "put",
        entityId: id,
        additionalRouteParts: routeParts,
        successHandler: () => setInitialLoading(false),
      });
    }
  };

  function previewFile(row: any) {
    let url = "/api/v1/file/" + `${row.getValue("id")}` + "/view/";

    if (children.subjectId !== "") {
      url =
        "/api/v1/file/" +
        `${row.getValue("id")}` +
        "/download/?impersonate_subject=" +
        `${children.subjectId}`;
    }
    return url;
  }

  const replaceDate = (dateValue: any, showTime = true) => {
    const formattedDate = dayjs(dateValue).format("DD.MM.YYYY");

    // If showTime is true, return the formatted date with time
    if (showTime) {
      return `${formattedDate}, ${new Date(dateValue).toLocaleTimeString()}`;
    }

    // Otherwise, return just the formatted date
    return formattedDate;
  };

  return (
    <>
      <MaterialReactTable
        localization={{
          ...MRT_Localization_DE,
        }}
        columns={columns}
        data={fileData}
        enableHiding={tableOptions.columnHiding}
        enableFullScreenToggle={false}
        enableFilters={tableOptions.filters}
        positionToolbarAlertBanner="none"
        enableDensityToggle={isMobile ? false : true}
        enableStickyHeader={true}
        enableStickyFooter={true}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            visibleInShowHideMenu: false,
            size: 40,
            muiTableHeadCellProps: {
              align: "center",
              sx: {
                padding: "0.5rem",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                padding: "0.5rem",
                textAlign: "center",
                borderRight: "1px solid #e0e0e0",
                "& .MuiIconButton-root": {
                  margin: 0,
                },
              },
            },
          },
          "mrt-row-select": {
            visibleInShowHideMenu: false,
            size: 40,
            muiTableHeadCellProps: {
              align: "center",
              sx: {
                padding: "0.5rem",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                padding: "0.5rem",
                textAlign: "center",
                borderRight: "1px solid #e0e0e0",
                "& .MuiIconButton-root": {
                  margin: 0,
                },
              },
            },
          },
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - 240px)",
          },
        }}
        muiTablePaperProps={{
          sx: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxShadow: props.tableBoxShadow && props.tableBoxShadow,
            marginTop: props.tableMargin
              ? props.tableMargin
              : isMobile
              ? "0"
              : "1rem",

            "&& .MuiCollapse-root, && .MuiCollapse-wrapperInner": {
              width: "100%",
            },
            "& .MuiAlert-root": {
              backgroundColor: "#fff",
            },
            "& .MuiInputBase-root, && .MuiInput-root::before, && .MuiInput-root::after":
              {
                borderBottom: "none",
              },
            "& .MuiTextField-root": {
              backgroundColor: "#f4f2f2",
              outline: "none",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                fontSize: "1.25rem",
              },
              "& .MuiInput-root": {
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
              },
            },
            "&& .MuiBox-root": {
              alignItems: "center",
            },
            "& .MuiTablePagination-root": {
              display: usePagination ? undefined : "none",
            },
            "& .MuiTableHead-root": { opacity: 1 },
          },
        }}
        muiTopToolbarProps={{
          sx: isMobile
            ? {
                "&& .MuiBox-root": {
                  display: "block",
                },
              }
            : {},
        }}
        muiTableBodyRowProps={({ row }: any) => {
          const isSecondToLastRow = row.index === data.length - 2;
          const endOfTableReached = fileData.length === rowCount;
          const shouldSetRef =
            !usePagination || isSecondToLastRow || !endOfTableReached;
          return {
            className: styles.tableRow,
            ref: shouldSetRef ? setLoadMoreDataTriggerEl : null,
            sx: {
              backgroundColor: row.index % 2 === 0 ? "#f5f5f5" : "#FFF",
            },
          };
        }}
        muiTableBodyCellProps={{
          sx: {
            borderRight: "1px solid #e0e0e0",
          },
        }}
        initialState={{
          columnVisibility: columnVisibility,
          density: props.customDensity ? props.customDensity : "spacious",
          showGlobalFilter: true,
          showColumnFilters:
            subdomain === "admin" ? props.showColumnFilterbyDefault : false,
        }}
        state={{
          pagination: pagination,
          globalFilter: globalFilter,
          columnFilters: columnFilter,
          isLoading: isLoading,
          showProgressBars: isRefetching,
          sorting: sorting,
          rowSelection: rowSelection,
        }}
        onRowSelectionChange={(rows: any) => setRowSelection(rows)}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: t("search_files"),
          onClick: () => {
            setGlobalFilter("");
          },
        }}
        onGlobalFilterChange={(e: any) => {
          setGlobalFilter(e);
          setInitialLoading(false);
        }}
        onColumnFiltersChange={(e: any) => {
          handleColumnFilter(e);
        }}
        enableColumnFilters={
          subdomain === "admin" ? props.allowColumnFiltering : false
        }
        onSortingChange={(e) => {
          setSorting(e), setInitialLoading(false);
        }}
        manualPagination={true}
        onPaginationChange={(e: any) => {
          setPagination(e);
          setInitialLoading(false);
        }}
        manualFiltering={true}
        rowCount={rowCount}
        enableRowSelection={props.rowSelection}
        renderTopToolbarCustomActions={({ table }) => {
          let toolBarItems: ReactNode = <></>;

          const submitMultiple = async (
            submitMethod: any,
            submitEntity: any,
            data?: any,
            skipQuestion?: false
          ) => {
            const additionalRouteParts: any = {};
            let confirmQuestion;

            if (submitMethod === "download") {
              const selectedRows = table.getSelectedRowModel().flatRows;

              selectedRows.forEach((file, index) => {
                setTimeout(() => {
                  const row = file.original;
                  props.downloadFile(row);
                }, index * 500);
              });

              return;
            }

            if (submitMethod === "edit") {
              for (const file of table.getSelectedRowModel().flatRows) {
                const row = file.original;
                props.openCreateModal(row);
              }
              return;
            }

            if (submitMethod === "delete") {
              confirmQuestion = t("selected_files_delete");
            }

            if (submitMethod === "put") {
              for (const key in data) {
                if (key === "visible_to_subject") {
                  if (data[key] === true) {
                    confirmQuestion = t("selected_files_set_visible");
                  } else confirmQuestion = t("selected_files_set_invisible");
                }
                if (key === "locked") {
                  if (data[key] === true) {
                    confirmQuestion = t("selected_files_lock_all");
                  } else confirmQuestion = t("selected_files_unlock_all");
                }
              }
            }

            if (!skipQuestion) {
              if (
                /*eslint-disable */
                !confirm(confirmQuestion)
                /*eslint-enable */
              ) {
                return;
              }
            }
            try {
              const promises = table
                .getSelectedRowModel()
                .flatRows.map((row) => {
                  return new Promise((resolve) => {
                    api.genericApiRequest({
                      method: submitMethod,
                      entity: submitEntity,
                      entityId: row.original.id,
                      additionalRouteParts: additionalRouteParts,
                      submitData: data,

                      successHandler: (res: any) => {
                        resolve(res.data);
                      },
                      failHandler: (error: any) => {
                        console.log(error);
                      },
                    });
                  });
                });
              await Promise.all(promises).then(() => {
                setInitialLoading(false);
                setRowSelection({});
                setFileData([]);
                setPagination({
                  pageIndex: 0,
                  pageSize: props.customPageSize ? props.customPageSize : 20,
                });
                setLoadMoreDataTriggerEl(null);
                fetchData(0);
              });
            } catch (error) {
              console.log(error);
            }
          };

          let paginationScrollToggle: ReactNode = <></>;
          if (!isMobile) {
            paginationScrollToggle = (
              <Tooltip
                title={
                  usePagination
                    ? t("change_to_infinite_scroll")
                    : t("change_to_pagination")
                }
                placement="top"
              >
                <IconButton
                  onClick={handlePaginationScrollToggle}
                  className={styles.paginationScrollToggleIcon}
                >
                  {usePagination ? <AutoStoriesIcon /> : <AllInclusiveIcon />}
                </IconButton>
              </Tooltip>
            );
          }

          toolBarItems = (
            <>
              {props.rowSelection && (
                <>
                  {props.subdomain === "admin" && (
                    <>
                      <Tooltip
                        title={t("visible_to_subject_all")}
                        placement="top"
                      >
                        <Button
                          onClick={() =>
                            submitMultiple("put", "file", {
                              visible_to_subject: true,
                            })
                          }
                        >
                          <VisibilityIcon color="success" />
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={t("not_visible_to_subject_all")}
                        placement="top"
                      >
                        <Button
                          onClick={() =>
                            submitMultiple("put", "file", {
                              visible_to_subject: false,
                            })
                          }
                        >
                          <VisibilityOffIcon color="error" />
                        </Button>
                      </Tooltip>

                      <Tooltip title={t("file_unlock_all")} placement="top">
                        <Button
                          onClick={() =>
                            submitMultiple("put", "file", { locked: false })
                          }
                        >
                          <LockOpenIcon color="success" />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t("file_lock_all")} placement="top">
                        <Button
                          onClick={() =>
                            submitMultiple("put", "file", { locked: true })
                          }
                        >
                          <LockIcon color="error" />
                        </Button>
                      </Tooltip>
                    </>
                  )}

                  {/* Separate container for action buttons on Mobile */}
                  <Box className={styles.mobileActionButtonsContainer}>
                    {isMobile && (
                      <Tooltip
                        title={
                          Object.keys(rowSelection).length === 0 &&
                          t("at_least_one_file_must_be_selected")
                        }
                        placement="top"
                      >
                        <span>
                          <Button
                            type="button"
                            color="success"
                            onClick={() => submitMultiple("download", "file")}
                            disabled={Object.keys(rowSelection).length === 0}
                            variant="contained"
                            className={styles.mobileActionButton}
                          >
                            <DownloadIcon className={styles.actionButtonIcon} />
                            {t("download")}
                          </Button>
                        </span>
                      </Tooltip>
                    )}

                    {isMobile && (
                      <Tooltip
                        title={
                          Object.keys(rowSelection).length === 0 &&
                          t("at_least_one_file_must_be_selected")
                        }
                        placement="top"
                      >
                        <span>
                          <Button
                            type="button"
                            color="info"
                            onClick={() => submitMultiple("edit", "file")}
                            disabled={
                              (subdomain !== "admin" &&
                                table
                                  .getSelectedRowModel()
                                  .flatRows.some(
                                    (row: any) => row.original.locked
                                  )) ||
                              Object.keys(rowSelection).length === 0 ||
                              Object.keys(rowSelection).length > 1
                            }
                            variant="contained"
                            className={styles.mobileActionButton}
                          >
                            <EditIcon className={styles.actionButtonIcon} />
                            {t("edit")}
                          </Button>
                        </span>
                      </Tooltip>
                    )}

                    <Tooltip
                      placement="top"
                      title={
                        disableDeleteAllButton &&
                        Object.keys(rowSelection).length !== 0 &&
                        "Mindestens eine der ausgewählten Dateien kann nicht gelöscht werden."
                      }
                    >
                      <span>
                        <Button
                          type="button"
                          color="error"
                          onClick={() => {
                            submitMultiple("delete", "file");
                          }}
                          disabled={disableDeleteAllButton}
                          variant="contained"
                          className={`${
                            isMobile ? styles.mobileActionButton : undefined
                          }`}
                        >
                          <DeleteIcon className={styles.actionButtonIcon} />
                          {t("delete")}
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </>
              )}
              {props.customTopToolBarAction && props.customTopToolBarAction}
            </>
          );
          return (
            <Fragment>
              {Object.keys(rowSelection).length !== 0 && (
                <div className={styles.toolBarItemsContainer}>
                  {toolBarItems}
                </div>
              )}
              {!isMobile && <div>{paginationScrollToggle}</div>}
            </Fragment>
          );
        }}
        enableRowActions={props.showRowActions}
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={0}
            disabled={subdomain !== "admin" && row.original.locked}
            onClick={() => {
              props.openCreateModal(row.original);
              closeMenu();
            }}
            className={styles.noMargin}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            {t("edit")}
          </MenuItem>,

          <MenuItem
            disabled={row.original.locked}
            key={1}
            onClick={() => {
              props.deleteFile(row.original);
              closeMenu();
            }}
            className={styles.noMargin}
          >
            <ListItemIcon>
              <DeleteIcon color="error" />
            </ListItemIcon>
            {t("delete")}
          </MenuItem>,
          <MenuItem
            key={2}
            onClick={() => {
              props.downloadFile(row.original);
              closeMenu();
            }}
            className={styles.noMargin}
          >
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            {t("download")}
          </MenuItem>,
          subdomain === "admin" && (
            <MenuItem
              key={3}
              onClick={() => {
                if (props?.moveFileSource?.length === 0) {
                  props.openMoveFileModal(row.original);
                }
                closeMenu();
              }}
              className={styles.noMargin}
            >
              <ListItemIcon>
                <DriveFileMoveIcon />
              </ListItemIcon>
              {t("move")}
            </MenuItem>
          ),
          subdomain === "admin" && (
            <MenuItem
              key={4}
              onClick={() => {
                row.original.reviewed
                  ? props.markAsUnReviewed(row.original)
                  : props.markAsReviewed(row.original);
                closeMenu();
              }}
              className={styles.noMargin}
            >
              <ListItemIcon>
                {row.original.reviewed ? (
                  <CheckCircleOutlineIcon color="error" />
                ) : (
                  <CheckCircleOutlineIcon color="success" />
                )}
              </ListItemIcon>
              {row.original.reviewed ? t("set_unreviewed") : t("set_reviewed")}
            </MenuItem>
          ),
          subdomain === "admin" && (
            <MenuItem
              key={5}
              onClick={() => {
                row.original.visible_to_subject
                  ? props.setInvisible(row.original)
                  : props.setVisible(row.original);
                closeMenu();
              }}
              className={styles.noMargin}
            >
              <ListItemIcon>
                {row.original.visible_to_subject ? (
                  <VisibilityOffIcon color="error" />
                ) : (
                  <VisibilityIcon color="success" />
                )}
              </ListItemIcon>
              {row.original.visible_to_subject
                ? t("not_visible_to_subject")
                : t("visible_to_subject")}
            </MenuItem>
          ),
          subdomain === "admin" && (
            <MenuItem
              key={6}
              onClick={() => {
                row.original.locked
                  ? props.setUnlocked(row.original)
                  : props.setLocked(row.original);
                closeMenu();
              }}
              className={styles.noMargin}
            >
              <ListItemIcon>
                {row.original.locked ? (
                  <LockOpenIcon color="success" />
                ) : (
                  <LockIcon color="error" />
                )}
              </ListItemIcon>
              {row.original.locked ? t("file_locked") : t("file_unlocked")}
            </MenuItem>
          ),
        ]}
        renderBottomToolbar={() => {
          if (!usePagination) {
            return (
              <Box className={styles.bottomToolbarWrapper}>
                <div>
                  <span className={styles.numOfFilesDisplayed}>
                    {t("number_of_files_displayed")}:&nbsp;
                  </span>
                  {fileData.length} {t("out_of")} {rowCount}
                </div>
                <div className={styles.spinnerContainer}>
                  <CircularProgress
                    size={20}
                    thickness={5}
                    color="secondary"
                    className={`
                        ${styles.loadingMoreSpinner}
                        ${
                          showLoadingMoreSpinner
                            ? styles.loadingMoreSpinnerVisible
                            : styles.loadingMoreSpinnerHidden
                        }
                      `}
                  />
                </div>
              </Box>
            );
          }
          return null;
        }}
      />
      {showScrollButton && (
        <Fab
          color="secondary"
          size="small"
          onClick={isPageAtTop ? scrollToBottom : scrollToTop}
          className={styles.scrollToTopButton}
        >
          {isPageAtTop ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </Fab>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default FileTable;
