import { Grid, Tooltip } from "@mui/material";
import React, { FC, useState } from "react";
import GeneralListItem from "../general/generaListItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { Collapse, IconButton, IconButtonProps, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./appointmentTreeItemForms.module.css";
import CheckIcon from "@mui/icons-material/Check";
import { t } from "i18next";

interface Props {
  formsToShow: "hidden" | "visible";
  forms: any[];
  appointmentId: any;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const AppointmentTreeItemForms: FC<Props> = ({
  formsToShow,
  forms,
  appointmentId,
}) => {
  const [invisibleFormsOpen, setInvisibleFormsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const renderItems = () => {
    const sortedFormsByExamination = forms.sort((a: any, b: any) =>
      a.examination_id.localeCompare(b.examination)
    );

    const filteredVisibleForms = sortedFormsByExamination.filter(
      (form: any) => !form?.form_hidden
    );
    const filteredHiddenForms = sortedFormsByExamination.filter(
      (form: any) => form?.form_hidden
    );

    const groupedVisibleFormsByExamination = filteredVisibleForms.reduce(
      (acc: any, obj: any) => {
        const key = obj.examination_id;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      },
      {}
    );
    Object.keys(groupedVisibleFormsByExamination).forEach((examination) => {
      groupedVisibleFormsByExamination[examination].sort((a: any, b: any) => {
        if (a.form_widget_id === "anamnese_allgemein") return -1;
        if (b.form_widget_id === "anamnese_allgemein") return 1;
        return 0;
      });
    });

    const groupedHiddenFormsByExamination = filteredHiddenForms.reduce(
      (acc: any, obj: any) => {
        const key = obj.examination;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      },
      {}
    );

    const handleNavigateToForm = (form: any) => {
      navigate(
        "/appointmentDashboard/" +
          appointmentId +
          "/" +
          form.form_id +
          "/" +
          form.finding_id
      );
    };

    const renderlabel = (currentForm: any, label: any) => {
      if (!currentForm.finding_id) {
        return (
          <div className={styles.itemWrapper}>
            <h6 className={styles.noMargin}>{currentForm.form_name}</h6>
            <div className={styles.itemContent}>
              {label}
              <ArrowForwardIcon />
            </div>
          </div>
        );
      } else
        return (
          <Tooltip title={"Formular öffnen"} placement="top-end">
            <div className={styles.itemWrapper}>
              <h6 className={styles.noMargin}>{currentForm.form_name}</h6>
              <div className={styles.itemContent}>
                {label}
                <ArrowForwardIcon />
              </div>
            </div>
          </Tooltip>
        );
    };
    if (formsToShow === "visible") {
      const examinationKeysForVisibleForms = Object.keys(
        groupedVisibleFormsByExamination
      );

      return examinationKeysForVisibleForms.map(
        (examination: any, index: any) => (
          <React.Fragment key={index}>
            {groupedVisibleFormsByExamination[examination].map(
              (currentForm: any) => {
                const label = (
                  <span className={styles.label}>
                    {currentForm.filled_measurements ===
                    currentForm.total_measurements ? (
                      <CheckIcon />
                    ) : (
                      `${currentForm.filled_measurements}/${currentForm.total_measurements}`
                    )}
                  </span>
                );

                return (
                  <GeneralListItem
                    key={currentForm.form_id}
                    allowHover={true}
                    onClickElement="wrapper"
                    onClick={() => handleNavigateToForm(currentForm)}
                    isDisabled={!currentForm.finding_id}
                    wrapperClassName={
                      currentForm.finding_id
                        ? styles.generalListItemWrapper
                        : styles.generalListItemWrapperDisabled
                    }
                    hideButton={true}
                  >
                    {renderlabel(currentForm, label)}
                  </GeneralListItem>
                );
              }
            )}
            {index < examinationKeysForVisibleForms.length - 1 && <hr />}
          </React.Fragment>
        )
      );
    }
    if (formsToShow === "hidden" && filteredHiddenForms.length !== 0) {
      const examinationKeysForHiddenForms = Object.keys(
        groupedHiddenFormsByExamination
      );
      return (
        <div>
          <ExpandMore
            expand={invisibleFormsOpen}
            onClick={handleShowInVisibleForms}
            aria-expanded={invisibleFormsOpen}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Collapse
            in={invisibleFormsOpen}
            className={styles.collapseContent}
            timeout="auto"
            unmountOnExit
          >
            {examinationKeysForHiddenForms.map(
              (examination: any, index: any) => (
                <React.Fragment key={index}>
                  {groupedHiddenFormsByExamination[examination].map(
                    (currentForm: any) => {
                      const label = (
                        <span className={styles.label}>
                          {currentForm.filled_measurements ===
                          currentForm.total_measurments ? (
                            <CheckIcon />
                          ) : (
                            `${currentForm.filled_measurements}/${currentForm.total_measurements}`
                          )}
                        </span>
                      );

                      return (
                        <GeneralListItem
                          key={currentForm.form_id}
                          allowHover={true}
                          onClickElement="wrapper"
                          onClick={() => handleNavigateToForm(currentForm)}
                          isDisabled={!currentForm.finding_id}
                          wrapperClassName={
                            currentForm.finding_id
                              ? styles.generalListItemWrapper
                              : styles.generalListItemWrapperDisabled
                          }
                          hideButton={true}
                        >
                          {renderlabel(currentForm, label)}
                        </GeneralListItem>
                      );
                    }
                  )}
                </React.Fragment>
              )
            )}
          </Collapse>
        </div>
      );
    }
  };

  const ExpandMore = styled((props: ExpandMoreProps) => {
    // eslint-disable-next-line
    const { expand, ...other } = props;
    return (
      <Grid item xs={12} className={styles.expandMoreButton}>
        <span>{t("more_forms")}</span>
        <IconButton {...other} />
      </Grid>
    );
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleShowInVisibleForms = () => {
    setInvisibleFormsOpen(!invisibleFormsOpen);
  };

  return (
    <Grid xs={12} item>
      <>{renderItems()}</>
    </Grid>
  );
};

export default AppointmentTreeItemForms;
