import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { KaerIcon } from "../../icons/kaerIcon";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useState } from "react";
import { t } from "i18next";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import "../styles/headerBar.css";
import styles from "./accountSettings.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import GenericButton from "../forms/inputs/button/genericButton";

interface Props {
  handleOpenSettings?: (event: any) => void;
  handleCloseSettings?: (event: any) => void;
  handleNavigateSettings?: () => void;
  combinedSignout: () => void;
  signOut: () => void;
  user?: any;
  email?: any;
  open: boolean;
  firstNameInitial?: string;
  lastNameInitial?: string;
  anchorEl?: any;
}

const AccountSettings: FC<Props> = ({
  handleOpenSettings,
  handleCloseSettings,
  handleNavigateSettings,
  combinedSignout,
  // eslint-disable-next-line
  signOut,
  open,
  firstNameInitial,
  lastNameInitial,
  anchorEl,
  user,
  email,
}) => {
  const [error, setError] = useState<any>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <GenericButton
        className={`headerBarUserIcon ${styles.iconButton}`}
        variant="contained"
        color="tertiary"
        onClick={handleOpenSettings}
        size="medium"
        aria-controls={open ? "userMenu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        {firstNameInitial}
        {lastNameInitial}
      </GenericButton>
      <Menu
        id="userMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseSettings}
        slotProps={{
          paper: {
            sx: {
              overflow: "visible",
              filter: isMobile
                ? "drop-shadow(0px 10px 20px rgba(0,0,0,0.9))"
                : "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: isMobile ? 2.0 : 1.5,
              pt: isMobile ? 2 : undefined,
              width: isMobile ? "100%" : undefined,
              "& .MuiAvatar-root": {
                width: 36,
                height: 36,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: isMobile ? 13 : 22,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <GenericButton
          variant="icon"
          color="iconBase"
          onClick={handleCloseSettings}
          className={styles.iconContainer}
        >
          <CloseIcon />
        </GenericButton>
        <ListItem className={styles.listItem}>
          <Avatar />
          <Box className={styles.userDetailsWrapper}>
            <Box component={"span"} className="headerBarMenuUserName">
              {user}
            </Box>
            <Box component={"span"} className="headerBarMenuUserMail">
              {email}
            </Box>
          </Box>
        </ListItem>
        <Divider />
        <MenuItem onClick={handleNavigateSettings}>
          <ListItemIcon>
            <KaerIcon icon="settings" />
          </ListItemIcon>
          {t("settings")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            api.genericApiRequest({
              entity: "logout",
              method: "post",
              successHandler: () => {
                combinedSignout();
              },
              failHandler: (error: any) => {
                combinedSignout();
                setError(error);
                alert("Es gab einen Fehler beim Logout.");
              },
            });
          }}
        >
          <ListItemIcon>
            <KaerIcon icon="logout" />
          </ListItemIcon>
          {t("logout")}
        </MenuItem>
      </Menu>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default AccountSettings;

//TODO CUSTOMERROR
