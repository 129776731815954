import ReactDOM from "react-dom/client";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Root from "./routes/root";
import Login from "./routes/login";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import StartPage from "./routes/startPage";
import Admin from "./routes/admin";
import Form from "./routes/form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import Document from "./routes/document";
import Todo from "./routes/todo";
import { CreateNewAppointmentRequest } from "./components/appointmentRequest/createNewAppointmentRequest";
import { AppointmentRequestDateTime } from "./components/appointmentRequest/appointmentRequestDateTime";
import { AppointmentRequestSlots } from "./components/appointmentRequest/appointmentRequestSlots";
import i18n from "./translations/i18n";
import { t } from "i18next";
import Register from "./routes/register";
import { SnackbarProvider } from "notistack";
import AppointmentDashboard from "./routes/appointmentDashboard";
import DataProtectionInformation from "./routes/dataProtectionInformation";
import UserSettings from "./routes/userSettings";
import ForgottenPasswordForm from "./components/forms/forgottenPasswordForm";
import UserAdministration from "./routes/userAdministration";
import { PersistGate } from "redux-persist/integration/react";
import AppointmentRequest from "./routes/appointmentRequest";
import ForgottenPassword from "./routes/forgottenPassword";
import AppointmentRequestTableView from "./routes/appointmentRequestTableView";
import AppointmentRequestCreate from "./routes/AppointmentRequestCreate";
import TimeSlotsEdit from "./components/appointmentRequest/timeSlotsEdit";
import AppointmentList from "./routes/admin/appointmentList";
import PermissionProvider from "./provider/permissionProvider";
import { Provider } from "react-redux";
import { persistor, store } from "./components/state/store";
import Buildings from "./routes/buildings";
import AppointmentRequestBuildingInformation from "./components/appointmentRequest/appointmentRequestBuildingInformation";
import PermissionGroupTable from "./components/permissions/permissionGroupTable";
import SubjectCompanyOverview from "./routes/admin/subjectCompanyOverview";
import TimeSlotPickConfirmation from "./components/appointmentRequest/timeSlotPickConfirmation";
import WorkerAppointments from "./routes/workerAppointments";
import PreventiveCareList from "./routes/preventiveCareList";
import Adminsettings from "./routes/adminSettings";
import AdminTrackedService from "./routes/adminTrackedService";
import TrackedServiceCompanyOverrideTemplate from "./components/trackedService/trackedServiceCompanyOverrideTemplate";
import { ReactFlowProvider } from "@xyflow/react";
import ActionChainActiveCompanyTable from "./components/actionChain/actionChainActiveCompanyTable";
import ActionChainProgress from "./components/actionChain/actionChainProgress";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

i18n.t(""); //Dummy, because we need to import the i18n file in the index file

const hostname = window.location.hostname;
const subdomain = hostname.split(".")[0];

let staffRoutes = <></>;

switch (subdomain) {
  case "admin":
    document.title = t("kaer_staff_portal");
    break;
  case "manage":
    document.title = t("kaer_manager_portal");
    break;
  default:
    document.title = t("kaer_employee_portal");
    break;
}

if (subdomain === "admin") {
  staffRoutes = (
    <>
      <Route path="/admin/*" element={<Admin />} />
      <Route path="/settings/*" element={<Adminsettings />} />
      <Route path="/trackedservice/*" element={<AdminTrackedService />} />

      <Route path="/appointmentDashboard" element={<AppointmentDashboard />} />
      <Route
        path="/appointmentDashboard/:appointmentId?"
        element={<AppointmentDashboard />}
      />
      <Route
        path="/appointmentDashboard/:appointmentId?/:formId?/:findingId?"
        element={<AppointmentDashboard />}
      />
      <Route
        path="/appointmentrequest"
        element={<AppointmentRequestTableView />}
      />
      <Route path="/appointmentList" element={<AppointmentList />} />
      <Route
        path="/subjectOverview"
        element={<SubjectCompanyOverview subdomain={subdomain} />}
      />

      <Route path="appointmentbooking" element={<AppointmentRequestCreate />} />
      <Route
        path="editappointmentrequest/:appointmentrequestId?/settings"
        element={<CreateNewAppointmentRequest />}
      />
      <Route
        path="editappointmentrequest/:appointmentrequestId?/building"
        element={<AppointmentRequestBuildingInformation />}
      />
      <Route
        path="editappointmentrequest/:appointmentrequestId?/calendar"
        element={<AppointmentRequestDateTime />}
      />
      <Route
        path="editappointmentrequest/:appointmentrequestId?/timeslots"
        element={<TimeSlotsEdit />}
      />
      <Route
        path="permissionGroupList/:groupId?/edit"
        element={<PermissionGroupTable />}
      />
    </>
  );
}

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AuthProvider
        authType={"cookie"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
      >
        <PermissionProvider>
          <ReactFlowProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={4000}
                preventDuplicate={true}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                classes={{ containerRoot: "zAlert" }}
              >
                <Router>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <RequireAuth loginPath={"/login/"}>
                          <Root subdomain={subdomain} />
                        </RequireAuth>
                      }
                    >
                      <Route index element={<StartPage />} />
                      {staffRoutes}
                      <Route path="usersettings" element={<UserSettings />} />
                      <Route path="todo" element={<Todo />} />
                      <Route path="document" element={<Document />} />
                      <Route
                        path="form/:findingId?/:formId?"
                        element={<Form />}
                      />
                      <Route
                        path="mitarbeiter"
                        element={<UserAdministration />}
                      />
                      <Route
                        path="/preventiveCareList"
                        element={<PreventiveCareList />}
                      />
                      <Route
                        path="appointmentrequestopen"
                        element={<AppointmentRequest subdomain={subdomain} />}
                      />
                      <Route
                        path="appointmentrequestopen/:appointmentrequestId?/pick"
                        element={<AppointmentRequestSlots />}
                      />
                      <Route
                        path="appointmentrequestopen/:appointmentrequestId?/confirm"
                        element={<TimeSlotPickConfirmation />}
                      />

                      <Route
                        path="/buildings/:subjectId?"
                        element={<Buildings />}
                      />

                      <Route
                        path="/trackedservice/company/:subjectId?"
                        element={<TrackedServiceCompanyOverrideTemplate />}
                      />
                      <Route
                        path="/actionchain/company/:subjectId?"
                        element={<ActionChainActiveCompanyTable />}
                      />
                      <Route
                        path="/actionchain/company/progress/:actionChainTemplateId?/:actionChainProgressId?"
                        element={<ActionChainProgress />}
                      />
                      <Route
                        path="/workerAppointments"
                        element={<WorkerAppointments />}
                      />
                    </Route>
                    <Route
                      path="/login"
                      element={<Login subdomain={subdomain} />}
                    />
                    <Route
                      path="/login/reminder/"
                      element={<ForgottenPassword />}
                    />
                    <Route
                      path="appointmentrequestopen/:appointmentrequestId?/pick"
                      element={<AppointmentRequestSlots />}
                    />
                    {/* <Route
                    path="appointmentrequestopen/:appointmentrequestId?/confirm"
                    element={<TimeSlotPickConfirmation />}
                  /> */}
                    <Route
                      path="/reset_password/:userId/:passwordResetToken/"
                      element={<ForgottenPasswordForm />}
                    />
                    <Route
                      path="/complete_registration/:userId/:requestToken/"
                      element={<Register />}
                    />
                    <Route
                      path="/datenschutz"
                      element={<DataProtectionInformation />}
                    />
                  </Routes>
                </Router>
              </SnackbarProvider>
            </LocalizationProvider>
          </ReactFlowProvider>
        </PermissionProvider>
      </AuthProvider>
    </PersistGate>
  </Provider>
);
