import React, { FC, useState } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
  type EdgeProps,
} from "@xyflow/react";
import styles from "./actionChains.module.css";
import { IconButton } from "@mui/material";
import classNames from "classnames";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface CustomConnectionProps extends EdgeProps {
  data: {
    onDelete: (event: React.MouseEvent, id: string) => void;
    itemId: any;
    id: any;
    targetData?: any;
  };
  type?: string;
}

const ActionChainCustomConnection: FC<CustomConnectionProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  type,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const companyProgressWrapperClassnames = classNames({
    [styles.edge_status_open]:
      data.targetData.status === "open" || !data.targetData.status,
    [styles.edge_status_inprogress]: data.targetData.status === "in_progress",
    [styles.edge_status_completed]: data.targetData.status === "completed",
  });
  const renderCustomConnection = () => {
    switch (type) {
      case "withDeleteHandler":
        return (
          <>
            <g
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
              <EdgeLabelRenderer>
                <div
                  className={styles.buttonLabel}
                  style={{
                    pointerEvents: "none",
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                  }}
                >
                  {isHovered && (
                    <IconButton
                      className={styles.connectionDeleteButton}
                      onClick={(event) => {
                        event.stopPropagation();
                        data.onDelete(event, id);
                      }}
                      style={{ pointerEvents: "auto" }}
                      sx={{
                        cursor: "pointer",
                        background: "#fff",
                        padding: "0",
                      }}
                    >
                      <HighlightOffIcon color="error" fontSize="large" />
                    </IconButton>
                  )}
                </div>
              </EdgeLabelRenderer>
            </g>
          </>
        );
        break;
      case "companyProgress":
        return (
          <BaseEdge
            path={edgePath}
            markerEnd={markerEnd}
            style={style}
            className={companyProgressWrapperClassnames}
          />
        );
    }
  };

  return <>{renderCustomConnection()}</>;
};

export default ActionChainCustomConnection;
