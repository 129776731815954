import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { IconButton, Tooltip } from "@mui/material";
import { replaceDate } from "../../helper/dateHandling";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ActionChainActiveCompanyTable: FC = () => {
  const [heading, setHeading] = useState<string>(
    t("actionchain_active_chains")
  );
  const { subjectId } = useParams() as {
    subjectId: string;
  };
  const navigate = useNavigate();

  const additionalUrlParameters: any = {};
  if (subjectId) {
    additionalUrlParameters["subject"] = subjectId;
  }

  const customRowActions = (row: any) => {
    return (
      <>
        <Tooltip arrow placement="right" title={t("actionchain_show_progress")}>
          <IconButton
            onClick={() => handleNavigateToChainProgress(row.original)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  const columns = [
    {
      header: t("name"),
      id: "name",
      accessorKey: "action_chain_template.name",
    },
    {
      header: t("status"),
      id: "status",
      accessorKey: "status",
    },
    {
      header: t("created_on"),
      accessorKey: "created_on",
      id: "created_on",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("modified_on"),
      accessorKey: "modified_on",
      id: "modified_on",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
  ];
  const handleNavigateBack = () => {
    navigate("/admin/companyList");
  };
  const handleNavigateToChainProgress = (row: any) => {
    navigate(
      `/actionchain/company/progress/${row?.action_chain_template?.id}/${row?.id}`
    );
  };

  const handleUpdateHeadingWithTableData = useCallback((data: any) => {
    if (!data || !data.results || !data.results[0]?.subject?.name) {
      return;
    }

    setHeading((prevHeading) => {
      const newSubjectName = data.results[0].subject?.name;

      if (prevHeading.includes(newSubjectName)) {
        return prevHeading;
      }

      return `${prevHeading} - ${newSubjectName}`;
    });
  }, []);
  return (
    <>
      <Tooltip placement="right" title={t("back")}>
        <IconButton
          sx={{ alignSelf: "flex-start", marginBottom: "1rem" }}
          onClick={handleNavigateBack}
        >
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <GenericCrudTable
        entity="actionChainProgress"
        columns={columns}
        additionalUrlParameter={additionalUrlParameters}
        disableAddItem
        disallowDelete={true}
        customRowActions={customRowActions}
        heading={heading}
        onSuccessfulGetHandler={handleUpdateHeadingWithTableData}
      />
    </>
  );
};

export default ActionChainActiveCompanyTable;
