import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import Modal from "../components/modal";
import FileUpload from "../components/files/fileUpload";
import FindingForm from "../components/forms/findingForm";
import GeneralListItem from "../components/general/generaListItem";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { api } from "../helper/api";
import { useSelector } from "react-redux";
import { RootState } from "../components/state/store";
import HighFiveIcon from "../icons/highFiveIcon";
import dayjs from "dayjs";
import "./todo.css";
import FlagIcon from "../icons/flagIcon";
import GenericErrorMessageModal from "../components/forms/errorHandling/genericErrorMessageModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DotLoader } from "react-spinners";
import Backdrop from "@mui/material/Backdrop";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import debounce from "lodash/debounce";
import CircularProgress from "@mui/material/CircularProgress";
import PageScrollTo from "../components/general/pageScrollTo";

export default function Todo() {
  const [todos, setTodos]: any = useState([]);
  const [todosCount, setTodosCount] = useState<number>(0);
  const [hasMoreResults, setHasMoreResults] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent]: any = useState("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortOption, setSortOption] = useState<string>("created_on");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const lastTodoRef = useRef<HTMLDivElement | null>(null);
  const [showLoadingMoreSpinner, setShowLoadingMoreSpinner] =
    useState<boolean>(false);
  const [includeDoneTodos, setIncludeDoneTodos] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("include_done_todos");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const limit = 20;

  const { t } = useTranslation();
  const selectedChildSubjectId = useSelector(
    (state: RootState) => state.parent.subjectId
  );

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const user = useSelector((state: RootState) => state.login);
  const child = useSelector((state: RootState) => state.parent);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const today = dayjs();

  const refreshTodosFromServer = (
    searchTerm?: string,
    includeDoneTodos?: boolean,
    offset = 0,
    sortOption?: string,
    sortDirection?: "asc" | "desc"
  ) => {
    setShowLoadingMoreSpinner(true);
    const additionalUrlParameters: any = {
      limit,
      offset,
    };

    if (searchTerm) {
      additionalUrlParameters["search"] = searchTerm;
    }
    if (includeDoneTodos === false) {
      additionalUrlParameters["done"] = false;
    }
    if (sortOption) {
      additionalUrlParameters["ordering"] =
        sortDirection === "desc" ? `-${sortOption}` : sortOption;
    }

    if (
      subdomain === "admin" &&
      user.subjectId !== "" &&
      child.subjectId === ""
    ) {
      additionalUrlParameters["subject"] = user.subjectId;
    }

    //currently disabled - needs additional implementations for tagging todos

    // if (subdomain === "admin") {
    //   additionalUrlParameters["staff"] = true;
    // }

    api.genericApiRequest({
      entity: "todo",
      method: "get",
      parametersToRender: {
        additionalUrlParameters: additionalUrlParameters,
        depth: "0",
      },
      successHandler: (res: any) => {
        setTodosCount(res.data.count);
        setTodos((prevTodos: any) =>
          offset === 0 ? res.data.results : [...prevTodos, ...res.data.results]
        );
        setShowLoadingMoreSpinner(false);
        setHasMoreResults(res.data.next !== null);
      },
      failHandler: (error: any) => {
        setError(error);
        setShowLoadingMoreSpinner(false);
      },
    });
  };

  const delayedSearch = useCallback(
    debounce((searchTerm: string, includeDoneTodos: boolean) => {
      refreshTodosFromServer(searchTerm, includeDoneTodos);
    }, 250),
    []
  );

  useEffect(() => {
    setTodos([]);
    setHasMoreResults(true);
    const resetOffset = 0;

    if (searchTerm.length === 0) {
      refreshTodosFromServer(
        undefined,
        includeDoneTodos,
        resetOffset,
        sortOption,
        sortDirection
      );
    } else if (searchTerm.length >= 3) {
      delayedSearch(searchTerm, includeDoneTodos);
    }

    return () => delayedSearch.cancel();
  }, [searchTerm, includeDoneTodos, delayedSearch, sortOption, sortDirection]);

  useEffect(() => {
    if (!lastTodoRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          !showLoadingMoreSpinner &&
          hasMoreResults
        ) {
          if (todos.length < todosCount) {
            const newOffset = todos.length;
            refreshTodosFromServer(
              searchTerm,
              includeDoneTodos,
              newOffset,
              sortOption,
              sortDirection
            );
          } else {
            if (lastTodoRef.current) {
              observer.disconnect();
            }
          }
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    observer.observe(lastTodoRef.current);

    return () => {
      if (lastTodoRef.current) {
        observer.unobserve(lastTodoRef.current);
      }
    };
  }, [
    lastTodoRef.current,
    showLoadingMoreSpinner,
    searchTerm,
    limit,
    todos.length,
    todosCount,
    includeDoneTodos,
    hasMoreResults,
    sortOption,
    sortDirection,
  ]);

  useEffect(() => {
    localStorage.setItem(
      "include_done_todos",
      JSON.stringify(includeDoneTodos)
    );
  }, [includeDoneTodos]);

  const resetModal = () => {
    setModalOpen(false);
    setModalContent(<></>);
    setModalTitle("");
  };

  const finishTodo = (todoItem: any, onSuccessHandler?: any) => {
    setIsLoading(true);
    const result = { impersonate_subject: selectedChildSubjectId };
    const additionalRouteParts: any = {};
    additionalRouteParts["mark_done"] = "/";
    api.genericApiRequest({
      entity: "todo",
      method: "put",
      successHandler: () => {
        setTodos((prevTodos: any) =>
          prevTodos.filter((todo: any) => todo.id !== todoItem.id)
        );
        if (onSuccessHandler) {
          onSuccessHandler();
        }
        setIsLoading(false);
      },
      failHandler: (error: any) => {
        setIsLoading(false);
        setError(error);
      },
      entityId: todoItem.id,
      additionalRouteParts: additionalRouteParts,
      submitData: result,
    });
  };

  const renderModalUploadContent = (todoItem: any) => {
    return (
      <FileUpload
        type={"file"}
        name={todoItem.target.filename}
        target_filename={todoItem.target.filename}
        folderId={todoItem.target.folder}
        label={""}
        externalSubmitEvent={""}
        onCancel={() => {
          resetModal();
        }}
        handleUploadSuccess={() => {
          finishTodo(todoItem, () => {
            resetModal();
            refreshTodosFromServer();
          });
        }}
      />
    );
  };

  const renderModalFormContent = (todoItem: any) => {
    return (
      <FindingForm
        withPaper={false}
        findingId={todoItem.target.finding}
        formId={todoItem.target.form}
        preSubmitConfirmMessage={t("confirm_submit_finding_form")}
        onSuccessfulFormSubmitHandler={() => {
          finishTodo(todoItem, () => {
            resetModal();
            refreshTodosFromServer();
          });
        }}
        onCancelFormHandler={() => {
          resetModal();
        }}
        onCloseModal={() => {
          setIsLoading(true);
          resetModal();
        }}
      />
    );
  };

  const getModalContent = (todoItem: any) => {
    let modalContent = <></>;
    switch (todoItem.type) {
      case "upload":
        modalContent = renderModalUploadContent(todoItem);
        break;
      case "form":
        modalContent = renderModalFormContent(todoItem);
        break;
    }

    return modalContent;
  };

  const openTodoActionInModal = (todoItem: any) => {
    setModalContent(getModalContent(todoItem));
    setModalOpen(true);
    setModalTitle(todoItem.target.filename);
  };

  const filteredAndSortedTodos = useMemo(() => {
    return includeDoneTodos ? todos : todos.filter((todo: any) => !todo.done);
  }, [todos, includeDoneTodos]);

  let todosList;

  if (todos.length === 0 && !searchTerm) {
    todosList = (
      <Grid container>
        <Grid item component={Paper} className="allDoneContainer">
          <h1 className="allDoneHeading">{t("all_done")}</h1>
          <div className="allDoneContentWrapper">
            <h4 className="allDoneContentHeading">
              <Trans i18nKey={"todo_all_done_message"} />
            </h4>
            <HighFiveIcon width="160" height="159" />
          </div>
        </Grid>
      </Grid>
    );
  } else if (filteredAndSortedTodos.length === 0 && searchTerm) {
    todosList = (
      <Grid container>
        <Grid item component={Paper} className="noSearchResultsContainer">
          <h1 className="noSearchResultsHeading">{t("no_results_found")}</h1>
          <HeartBrokenIcon color="secondary" className="noSearchResultsIcon" />
          <div className="noSearchResultsContentWrapper">
            <h4 className="noSearchResultsContentHeading">
              {t("todo_no_matching_entries_message", { searchTerm })}
            </h4>
          </div>
        </Grid>
      </Grid>
    );
  } else {
    todosList = (
      <Grid container item>
        <Grid container item sm={12} component={List} rowSpacing={"1rem"}>
          {filteredAndSortedTodos.map((todoItem: any, index: number) => {
            const remainingDays = dayjs(todoItem.due_date).diff(today, "day");
            const buttonsConfig = [];

            if (!todoItem.done) {
              if (todoItem.type === "text") {
                buttonsConfig.push({
                  tooltip: t("mark_todo_as_done"),
                  icon: "checkmark",
                  backgroundColor: "#73be2a",
                  onClick: () => {
                    if (confirm(t("mark_todo_as_done_question"))) {
                      finishTodo(todoItem, () => {
                        refreshTodosFromServer();
                      });
                    }
                  },
                });

                if (
                  todoItem.target &&
                  todoItem.target.link &&
                  todoItem.target.linkTitle
                ) {
                  buttonsConfig.push({
                    tooltip: todoItem.target.linkTitle,
                    icon: "arrowRight",
                    backgroundColor: "#ED6C02",
                    onClick: () => {
                      window.open(
                        todoItem.target.link,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    },
                  });
                }
              } else {
                buttonsConfig.push({
                  icon: "arrowRight",
                  backgroundColor: "#ED6C02",
                  onClick: () => {
                    openTodoActionInModal(todoItem);
                  },
                });
              }
            }

            const isSecondLast = index === filteredAndSortedTodos.length - 2;

            return (
              <Grid
                item
                sm={12}
                key={`${todoItem.id}-${index}`}
                className="todoItem"
                ref={isSecondLast ? lastTodoRef : null}
              >
                <GeneralListItem
                  key={`${todoItem.id}-${index}`}
                  buttonsConfig={buttonsConfig}
                  wrapperClassName="generalListItemWrapper"
                  allowHover={false}
                  buttonWidth={isMobile ? "40vw" : undefined}
                  borderRadius={isMobile ? "0.5rem" : undefined}
                >
                  <div className="todoItemContentWrapper">
                    {!isMobile || todoItem.done ? (
                      <div className="todoCheckboxRound">
                        <input
                          type="checkbox"
                          checked={todoItem.done}
                          id={`todoDone-${todoItem.id}`}
                          readOnly={true}
                        />
                        <label htmlFor={`todoDone-${todoItem.id}`}></label>
                      </div>
                    ) : null}
                    <ListItem className="todoListItem">
                      <ListItemText primary={todoItem.name} />
                      {!isMobile ? (
                        <ListItemText
                          secondary={todoItem.description}
                          className="todoItemDescription"
                          style={{ whiteSpace: "pre-line" }}
                        />
                      ) : (
                        <ListItemText
                          primary={todoItem.description}
                          className="todoItemDescription"
                          style={{ whiteSpace: "pre-line" }}
                        />
                      )}
                      <ListItemText
                        className="todoListItemText"
                        primary={
                          <div className="listItemTextWrapper">
                            {!todoItem.done && (
                              <Typography
                                component={"span"}
                                color="text.primary"
                                className="remainingDays"
                              >
                                <span className="remainingDaysIconWrapper">
                                  <FlagIcon width="12" height="14" />
                                </span>
                                <Trans
                                  i18nKey={
                                    remainingDays <= 1
                                      ? t("remaining_day", {
                                          day: remainingDays,
                                        })
                                      : t("remaining_days", {
                                          days: remainingDays,
                                        })
                                  }
                                />
                              </Typography>
                            )}
                            <Typography color="text.secondary">
                              {t("todo_until", {
                                date: dayjs(todoItem.due_date).format(
                                  "DD.MM.YYYY"
                                ),
                              })}
                            </Typography>
                          </div>
                        }
                      />
                    </ListItem>
                  </div>
                </GeneralListItem>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }

  const searchFilterSortSection = (
    <Grid item xs={12} className="searchFilterSortWrapper">
      <Grid item xs={12}>
        <TextField
          placeholder={t("search_todos")}
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm ? (
              <InputAdornment position="end">
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setSearchTerm("")}
                />
              </InputAdornment>
            ) : null,
            className: "searchFieldInput",
          }}
          className="searchField"
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        rowSpacing={"1rem"}
        className="filterSortContainer"
      >
        <Grid item sm={6} xs={12} className="sortSelectContainer">
          <FormControl fullWidth>
            <InputLabel id="sort-select-label">{t("sort_by")}</InputLabel>
            <Select
              labelId="sort-select-label"
              id="sort-select"
              value={sortOption}
              label={t("sort_by")}
              onChange={(e) => setSortOption(e.target.value as string)}
              className="filerSelect"
              MenuProps={{
                PaperProps: {
                  sx: {
                    left: isMobile ? "8px !important" : undefined,
                  },
                },
              }}
            >
              <MenuItem value="created_on">{t("creation_date")}</MenuItem>
              <MenuItem value="due_date">{t("due_datetime")}</MenuItem>
              <MenuItem value="name">{t("title")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12} className="sortDirectionContainer">
          <ToggleButtonGroup
            value={sortDirection}
            exclusive
            onChange={(e, newDirection) => {
              if (newDirection) setSortDirection(newDirection);
            }}
            aria-label={t("sort_direction")}
            className="sortDirectionToggle"
            fullWidth
            sx={{
              borderRadius: "0.5rem",
              height: isMobile ? undefined : "56px",
              "& .MuiToggleButton-root": {
                borderRadius: 0,
              },
              "& .MuiToggleButtonGroup-grouped:first-of-type": {
                borderTopLeftRadius: "0.5rem",
                borderBottomLeftRadius: "0.5rem",
              },
              "& .MuiToggleButtonGroup-grouped:last-of-type": {
                borderTopRightRadius: "0.5rem",
                borderBottomRightRadius: "0.5rem",
              },
            }}
          >
            <ToggleButton value="asc" aria-label={t("ascending")}>
              {t("ascending")}
            </ToggleButton>
            <ToggleButton value="desc" aria-label={t("descending")}>
              {t("descending")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={12} className="doneFilterContainer">
        <FormControlLabel
          control={
            <Switch
              checked={includeDoneTodos}
              onChange={(e) => {
                setIncludeDoneTodos(e.target.checked);
              }}
              color="secondary"
            />
          }
          label={t("include_done_todos")}
          labelPlacement="start"
          className="doneFilterSwitch"
        />
      </Grid>
    </Grid>
  );

  const bottomSpinner = showLoadingMoreSpinner && (
    <Grid container className="bottomSpinnerWrapper">
      <CircularProgress
        size={30}
        thickness={5}
        color="secondary"
        className="loadingMoreSpinner"
      />
    </Grid>
  );

  return (
    <>
      <Grid container rowSpacing={"1rem"}>
        <Grid item xs={12}>
          <h1 className="todosHeader">{t("your_todos")}</h1>
        </Grid>
        {searchFilterSortSection}
        {todosList}
        {bottomSpinner}
      </Grid>
      {isLoading ? (
        <Backdrop className="backdrop" open={true}>
          <DotLoader
            color="#8c1ec8"
            size={65}
            cssOverride={{
              position: "absolute",
              top: "45vh",
              left: "50vw",
              zIndex: 1000,
            }}
          />
        </Backdrop>
      ) : (
        <Modal
          open={modalOpen}
          title={modalTitle}
          hideDialogActions={true}
          onClose={() => setModalOpen(false)}
        >
          {modalContent}
        </Modal>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      <PageScrollTo
        scrollDirection="up"
        showButtonCondition={todos.length >= 10}
        position={isMobile ? "right" : "center"}
      />
    </>
  );
}
