import React, { useEffect, useState } from "react";

interface Props {
  labelStyle?: React.CSSProperties;
  labelClassName?: string;
  fieldStyle?: React.CSSProperties;
  fieldClassName?: string;
  mandatory?: boolean;
  label?: string;
  name?: string;
  readonly?: boolean;
  required?: boolean;
  rows?: number;
  cols?: number;
  value?: string;
  defaultValue?: string;
  onChange?: (event: any) => void;
  withLabel?: boolean;
  wrapperStyle?: React.CSSProperties;
  wrapperClassName?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  function TextArea(props, ref) {
    const [value, setValue] = useState<string | undefined>(props.value ?? props.defaultValue);

    useEffect(() => {
      if (props.value !== "" && props.value !== undefined) {
        setValue(props.value);
      }
    }, [props.value]);

    const onChange = (event: any) => {
      setValue(event.target.value);
      if (props.onChange) {
        props.onChange(event);
      }
    };

    const wrapperStyle = {
      width: "100%",
      ...props.wrapperStyle,
    } as React.CSSProperties;

    const labelStyle = {
      display: "flex",
      marginBottom: "0.25rem",
      ...props.labelStyle,
    } as React.CSSProperties;

    const fieldStyle = {
      border: "1px solid #bcbcbc",
      outline: "none",
      padding: "0.75rem",
      borderRadius: "0.5rem",
      whiteSpace: "preserve",
      width: "100%",
      ...props.fieldStyle,
    } as React.CSSProperties;

    return (
      <div className={props.wrapperClassName} style={wrapperStyle}>
        {props.withLabel && (
          <label
            className={props.labelClassName}
            style={labelStyle}
            htmlFor={props.name}
          >
            {props.mandatory === true
              ? `${props.label + "\u{002A}"}`
              : props.label}
          </label>
        )}
        <textarea
          name={props.name}
          ref={ref}
          id={props.name}
          readOnly={props.readonly}
          required={props.required}
          rows={props.rows ? props.rows : 5}
          cols={props.cols}
          value={value}
          defaultValue={props.defaultValue}
          onChange={onChange}
          className={props.fieldClassName}
          style={fieldStyle}
        />
      </div>
    );
  }
);
