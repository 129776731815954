import { useWatch } from "react-hook-form";

export const useConditionalWatch = (
  control: any,
  options: {
    entity: any;
    field: string;
    condition: (entity: string) => boolean;
  }
) => {
  const { entity, field, condition } = options;

  if (!condition(entity)) {
    return null;
  }

  return useWatch({ control, name: field });
};
