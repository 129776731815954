import "./documents.css";
import { useState } from "react";
// import FileUpload from "./fileUpload";
import Modal from "../modal";
import DefaultInput from "../forms/inputs/defaultInput";
import FolderTree from "./folderTree";
import { IFileItem } from "../../types/FileItem";
import { useTranslation } from "react-i18next";
import FileTable from "./fileTable";
import FileUploadInput from "../forms/inputs/upload";
import { api } from "../../helper/api";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styles from "./fileList.module.css";
import GenericButton from "../forms/inputs/button/genericButton";

interface Props {
  subjectId?: string;
  subjectTreeId?: string;
  folderId: string;
  folderName?: string;
  onClickHandler?: (id: string) => void;
  query?: string;
  sortByName?: any;
  sortByDate?: any;
  folderUpdate?: any;
}

export default function FileList(props: Props) {
  const { t } = useTranslation();
  const [modalPresetValues, setModalPresetValues] = useState<IFileItem>();
  const [modalValue, setModalValue] = useState<any>([]);
  const [modalUploadOpen, setModalUploadOpen] = useState<boolean>(false);
  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
  const [modalMoveOpen, setModalMoveOpen] = useState<boolean>(false);
  const [moveFileSource, setMoveFileSource] = useState<any>([]);
  const [moveFileTarget, setMoveFileTarget] = useState<string>("");
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  const selectedChildCompany = useSelector((state: RootState) => state.parent);

  const handleUploadReset = () => {
    setUploadSuccess(false);
  };

  const editFileName = () => {
    const result = {
      name: modalValue.name,
      impersonate_subject: selectedChildCompany.subjectId,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
        props.folderUpdate();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(modalPresetValues?.id),
      submitData: result,
      config: config,
    });
    setModalEditOpen(false);
  };

  const openCreateModal = (item: any) => {
    setModalPresetValues(item);
    setModalValue({
      name: item.name,
      extension: item.extension,
    });
    setModalEditOpen(true);
  };

  const handleModalChange = (event: any) => {
    event.preventDefault();
    setModalValue({
      name: event.target.value,
      extension: modalValue.extension,
    });
  };

  const handleDownloadFile = (row: any) => {
    let url = "/api/v1/file/" + row.id + "/download/";

    if (selectedChildCompany.subjectId !== "") {
      url =
        "/api/v1/file/" +
        `${row.getValue("id")}` +
        "/download/?impersonate_subject=" +
        `${selectedChildCompany.subjectId}`;
    }

    return (window.location.href = url);
  };

  const deleteFile = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("delete_item_question", {
            itemName: item.name,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }

    const fetchData = async () => {
      try {
        // eslint-disable-next-line
        const res = await api.genericApiRequest({
          entity: "file",
          method: "delete",
          successHandler: () => {
            setMoveFileSource([]);
            setUploadSuccess(true);
            props.folderUpdate();
          },
          entityId: String(item.id),
        });
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  };

  const cancelMoveFile = () => {
    setMoveFileSource([]);
    setModalMoveOpen(false);
  };

  const openMoveFileModal = (item: IFileItem) => {
    setMoveFileSource(item);
    setModalMoveOpen(true);
  };
  const handleMoveFile = () => {
    const sourceResult = { folder_id: moveFileTarget };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(moveFileSource?.id),
      submitData: sourceResult,
      config: config,
    });

    setModalMoveOpen(false);
  };

  const markAsReviewed = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("set_status_to_reviewed_for_item", {
            itemName: item.name,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }
    const additionalRouteParts: any = {};
    additionalRouteParts["mark_reviewed"] = "/";
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
        props.folderUpdate();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: item.id,
      additionalRouteParts: additionalRouteParts,
    });
  };

  const markAsUnReviewed = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("set_status_to_unreviewed_for_item", {
            itemName: item.name,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }
    const additionalRouteParts: any = {};
    additionalRouteParts["mark_unreviewed"] = "/";
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
        props.folderUpdate();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: item.id,
      additionalRouteParts: additionalRouteParts,
    });
  };
  const markAsVisibleToSubject = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("set_visible_to_subject", {
            itemName: item.name,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }

    const result = { visible_to_subject: true };
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
        props.folderUpdate();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: item.id,
      submitData: result,
    });
  };
  const markAsInvisibleToSubject = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("set_invisible_to_subject", {
            itemName: item.name,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }

    const result = { visible_to_subject: false };
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
        props.folderUpdate();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: item.id,
      submitData: result,
    });
  };
  const markAsLocked = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("set_locked", {
            itemName: item.name,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }

    const result = { locked: true };
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
        props.folderUpdate();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: item.id,
      submitData: result,
    });
  };

  const markAsUnLocked = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("set_unlocked", {
            itemName: item.name,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }

    const result = { locked: false };
    api.genericApiRequest({
      entity: "file",
      method: "put",
      successHandler: () => {
        setMoveFileSource([]);
        setUploadSuccess(true);
        props.folderUpdate();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: item.id,
      submitData: result,
    });
  };

  return (
    <>
      {(((subdomain === "manage" || subdomain === "portal") &&
        props.folderName?.toLowerCase() === "upload") ||
        (subdomain === "admin" &&
          props.folderName &&
          props.folderName.toLowerCase() !== "upload")) && (
        <>
          <GenericButton
            className={styles.uploadButton}
            onClick={() => {
              setModalUploadOpen(true);
            }}
          >
            {t("upload")}
          </GenericButton>

          <Modal
            open={modalUploadOpen}
            onClose={() => setModalUploadOpen(false)}
            title={t("upload_file")}
            hideDialogActions={true}
          >
            <FileUploadInput
              multiple={true}
              subjectId={props.subjectId}
              folderId={props.folderId}
              name="file"
              onCancel={() => {
                setModalUploadOpen(false);
              }}
              handleUploadSuccess={(modalOpen: boolean) => {
                if (modalOpen) {
                  setModalUploadOpen(false);
                }
                setUploadSuccess(true);
                props.folderUpdate();
              }}
            />
          </Modal>
        </>
      )}
      <Modal
        presetValues={modalPresetValues}
        open={modalEditOpen}
        onClose={() => setModalEditOpen(false)}
        submitButtonText={t("save")}
        onSubmit={editFileName}
        title={t("edit_file")}
      >
        <DefaultInput value={modalValue.name} onChange={handleModalChange} />
      </Modal>
      <Modal
        presetValues={modalPresetValues}
        open={modalMoveOpen}
        onClose={cancelMoveFile}
        submitButtonText={t("move")}
        onSubmit={handleMoveFile}
        title={t("choose_folder")}
      >
        <div>
          <FolderTree
            onClickHandler={(folderId: any) => setMoveFileTarget(folderId)}
            treeId={props.subjectTreeId}
            folderId={props.folderId}
            // moveFileSource={moveFileSource}
            moveFileTarget={moveFileTarget}
            subjectId={props.subjectId}
          />
        </div>
      </Modal>
      <FileTable
        rowSelection={true}
        handleUploadReset={handleUploadReset}
        openCreateModal={openCreateModal}
        deleteFile={deleteFile}
        openMoveFileModal={openMoveFileModal}
        moveFileSource={moveFileSource}
        markAsReviewed={markAsReviewed}
        markAsUnReviewed={markAsUnReviewed}
        folderId={props.folderId}
        subjectId={props.subjectId}
        uploadSuccess={uploadSuccess}
        setVisible={markAsVisibleToSubject}
        setInvisible={markAsInvisibleToSubject}
        setLocked={markAsLocked}
        setUnlocked={markAsUnLocked}
        downloadFile={handleDownloadFile}
        showRowActions={isMobile ? false : true}
        subdomain={subdomain}
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
      />
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
