import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  addEdge,
  Background,
  ReactFlow,
  BackgroundVariant,
  Controls,
  MiniMap,
  applyNodeChanges,
  applyEdgeChanges,
  MarkerType,
  Node,
  Edge,
  ReactFlowProvider,
} from "@xyflow/react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { t } from "i18next";
import { api } from "../../helper/api";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./actionChains.module.css";
import "@xyflow/react/dist/style.css";
import ActionChainCustomNode from "./actionChainCustomNode";
import ActionChainCustomConnection from "./actionChainCustomConnection";
import ActionChainModalContent from "./actionChainModalContent";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { v4 as uuidv4 } from "uuid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GenericButton from "../forms/inputs/button/genericButton";

type User = {
  id: string;
  name: string;
};

type ActionChainTemplate = { id: string; name: string } | string;

type ActionChainItem = {
  id: string;
  created_by: User;
  modified_by: User;
  created_on: string;
  modified_on: string;
  metadata: any;
  deleted: boolean;
  enabled: boolean;
  name: string;
  action: string;
  additional_data: string;
  action_chain_template: ActionChainTemplate;
  previous_items_or: any[];
  previous_items_and: any[];
  is_entrypoint?: boolean;
};

type ActionKeys = "create_todo" | "generate_pdf" | "start_chain" | "send_email";

export interface CustomNode extends Node {
  actionChainItem: ActionChainItem;
}
const ActionChainCreateAndEdit: FC = () => {
  const [action, setAction] = useState<CustomNode[]>([]);
  const [connection, setConnection] = useState<Edge[]>([]);
  const [actionNameModalOpen, setActionNameModalOpen] =
    useState<boolean>(false);
  const [actionOptions, setActionOptions] = useState<any[]>([]);
  const [actionChainTemplateName, setActionChainTemplateName] =
    useState<string>("");
  const [lastActionNodeY, setLastActionNodeY] = useState<number>(0);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [refreshTrigger, setRefreshtrigger] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const navigate = useNavigate();
  const { actionChainId } = useParams() as {
    actionChainId: string;
  };
  const methods = useForm<any>({
    defaultValues: {
      name: "",
      action: { value: "", label: "" },
      additional_parameters: "{}",
    },
  });

  const nodesWithDeleteHandlerWidth = "300px";
  const nodesWithDeleteHandlerHeight = "95px";
  const companyProgressNodeWidth = "3000px";
  const companyProgressNodeHeight = "";

  const actionParameterMap: Record<ActionKeys, string> = {
    create_todo: `{"done":false,"name":"TITLE","type":"text","target":{"link":"URL","linkTitle":"LINK_TITLE"},"description":"DESCRIPTION","due_date_in_days":28}`,
    generate_pdf: `{"filename_template": "SOME_CUSTOM_FILENAME_{{ entity.subject.name }}", "template":"TEMPLATE_NAME", "my_custom_data": "Can be accessed inside of template via additional_data.my_custom_data"}`,
    start_chain: "{}",
    send_email: "{}",
  };

  const actionValue = methods.watch("action") as { value: ActionKeys } | null;
  useEffect(() => {
    if (typeof actionValue === "object" && actionValue !== null) {
      const additionalParams = actionParameterMap[actionValue.value];
      if (additionalParams) {
        methods.setValue("additional_parameters", additionalParams);
      }
    }
  }, [actionValue]);

  useEffect(() => {
    if (actionChainId != null) {
      getActionChainTemplateItemsById(
        actionChainId,
        action,
        setAction,
        setConnection,
        setLastActionNodeY,
        setError
      );
      getActionChain(actionChainId, setActionChainTemplateName, setError);
    }
  }, [actionChainId, refreshTrigger]);

  const openModal = () => {
    methods.reset();
    setModalMode("create");
    setActionNameModalOpen(true);
  };

  const closeModal = () => {
    setActionNameModalOpen(false);
  };

  useEffect(() => {
    api.genericApiRequest({
      method: "get",
      entity: "actionChainItemTemplate",
      additionalRouteParts: { available_actions: "/" },
      successHandler: (res: any) => {
        const actions = res.data
          .filter((action: any) => action !== "mark_completed")
          .map((action: any) => ({
            value: action,
            label: action,
          }));
        setActionOptions(actions);
      },
      failHandler: "",
    });
  }, []);

  const onActionChange = useCallback(
    (changes: any) => setAction((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onConnectionChange = useCallback(
    (changes: any) => setConnection((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onAddConnection = useCallback(
    (params: any) => {
      const sourceNode = action.find((node) => node.id === params.source);
      const targetNode = action.find((node) => node.id === params.target);

      const sourceNodeData = sourceNode?.data || {};
      const targetNodeData = targetNode?.data || {};

      const updatedActions = action.map((node: any) => {
        if (node.id === params.target) {
          return {
            ...node,
            actionChainItem: {
              ...node.actionChainItem,
              previous_items_and: [
                ...(node.actionChainItem.previous_items_and || []),
                { id: params.source, label: sourceNodeData.label },
              ],
            },
          };
        }
        return node;
      });

      setConnection((eds) =>
        addEdge(
          {
            ...params,
            type: "withDeleteHandler",
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
            data: {
              sourceData: sourceNodeData,
              targetData: targetNodeData,
            },
          },
          eds
        )
      );
      setAction(updatedActions);
    },
    [action]
  );

  const onDeleteConnection = useCallback(
    (event: React.MouseEvent, id: string) => {
      event.stopPropagation();
      const confirmDelete = window.confirm(
        t("actionchain_confirm_delete_connection")
      );
      if (confirmDelete) {
        setConnection((connection: any) => {
          const connectionToDelete = connection.find((e: any) => e.id === id);

          if (connectionToDelete) {
            const { source, target } = connectionToDelete;

            setAction((prevActions) =>
              prevActions.map((action: any) => {
                if (action.id === target) {
                  return {
                    ...action,
                    actionChainItem: {
                      ...action.actionChainItem,
                      previous_items_and: (
                        action.actionChainItem.previous_items_and || []
                      ).filter((item: any) => item.id !== source),
                    },
                  };
                }
                return action;
              })
            );
          }

          return connection.filter((e: any) => e.id !== id);
        });
      }
    },
    []
  );

  const onDeleteNode = useCallback(
    (nodeId: string) => {
      const confirmDelete = window.confirm(
        t("actionchain_confirm_delete_action")
      );
      if (confirmDelete) {
        setAction((nds) =>
          nds.map((node: any) =>
            node.id === nodeId
              ? {
                  ...node,
                  actionChainItem: {
                    ...node.actionChainItem,
                    metadata: {
                      ...node.actionChainItem.metadata,
                      submitMethod: "delete",
                      deleted: true,
                    },
                  },
                }
              : node
          )
        );

        setConnection((eds) =>
          eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)
        );
      }
    },
    [setAction, setConnection]
  );

  const removeDeletedNodesFromState = (deletedNodeIds: string[]) => {
    setAction((prevNodes) =>
      prevNodes.filter((node) => !deletedNodeIds.includes(node.id))
    );
    setConnection((prevEdges) =>
      prevEdges.filter(
        (edge) =>
          !deletedNodeIds.includes(edge.source) &&
          !deletedNodeIds.includes(edge.target)
      )
    );
  };

  const handleModalOpenOnNodeEdit = (
    open: boolean,
    id: string,
    { label, action, additional_data }: any
  ) => {
    setModalMode("edit");
    methods.setValue("name", label);
    methods.setValue("action", action);
    methods.setValue("additional_data", additional_data);
    methods.setValue("id", id);
    setActionNameModalOpen(open);
  };
  const nodesWithDeleteHandler = action
    .filter((node) => node.actionChainItem?.metadata.submitMethod !== "delete")
    .map((node) => ({
      ...node,
      type: "withDeleteHandler",
      data: {
        ...node.data,
        onDelete: onDeleteNode,
        onOpenModal: handleModalOpenOnNodeEdit,
      },
    }));

  const connectionWithDeleteHandler = connection.map((con) => ({
    ...con,
    type: "withDeleteHandler",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    data: {
      ...con.data,
      onDelete: onDeleteConnection,
    },
  }));

  const addAction = (data: any) => {
    const NODE_SPACING = 150;
    const SCREEN_WIDTH = window.innerWidth;
    const newUuId = uuidv4();

    const additionalDataForItemTemplate: Partial<ActionChainItem> = {
      name: data.name,
      action: data.action.value,
      additional_data: data.additional_parameters,
      action_chain_template: actionChainId,
      previous_items_and: [],
      previous_items_or: [],
      metadata: {
        position: {
          x: SCREEN_WIDTH / 2 - 100,
          y: lastActionNodeY + NODE_SPACING,
        },
        submitMethod: "post",
      },
    };

    const newNode = {
      id: newUuId,
      position: {
        x: SCREEN_WIDTH / 2 - 100,
        y: lastActionNodeY + NODE_SPACING,
      },

      data: {
        id: newUuId,
        label: data.name,
        action: data.action.value,
        additional_data: data.additional_parameters,
      },
      actionChainItem: additionalDataForItemTemplate,
    };
    setLastActionNodeY(lastActionNodeY + NODE_SPACING);
    setAction((prevNodes: any) => [...prevNodes, newNode]);

    methods.reset();
    closeModal();
  };
  const editAction = (data: any) => {
    const idToEdit = data.id;

    const tempSlicedArray = action.map((item: any) =>
      item.id === idToEdit
        ? {
            ...item,
            data: {
              ...item.data,
              label: data.name,
              action: data.action.value,
              additional_data: data.additional_data,
            },
            actionChainItem: {
              ...item.actionChainItem,
              name: data.name,
              action: data.action.value,
              additional_data: data.additional_data,
            },
          }
        : item
    );
    setAction(tempSlicedArray);

    setActionNameModalOpen(false);
  };

  const handleCreateAndEditNode = (data: any) => {
    modalMode === "create" ? addAction(data) : editAction(data);
  };

  const nodeTypes = useMemo(
    () => ({
      withDeleteHandler: (props: any) => (
        <ActionChainCustomNode
          {...props}
          nodeWidth={nodesWithDeleteHandlerWidth}
          nodeHeight={nodesWithDeleteHandlerHeight}
        />
      ),
      companyProgress: (props: any) => (
        <ActionChainCustomNode
          {...props}
          nodeWidth={companyProgressNodeWidth}
          nodeHeight={companyProgressNodeHeight}
          fontSize="12px"
        />
      ),
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      withDeleteHandler: (props: any) => (
        <ActionChainCustomConnection {...props} />
      ),
      companyProgress: (props: any) => (
        <ActionChainCustomConnection {...props} />
      ),
    }),
    []
  );

  const handleNavigateBack = () => {
    navigate("/settings/actionChains");
  };

  return (
    <>
      <ReactFlowProvider>
        <div style={{ width: "90vw", height: "90vh" }}>
          <div className={styles.heading_wrapper}>
            <Tooltip placement="right" title={t("back")}>
              <GenericButton
                variant="icon"
                color="iconBase"
                className={styles.backButton}
                onClick={handleNavigateBack}
              >
                <ArrowBackIcon />
              </GenericButton>
            </Tooltip>
            <h1 className={styles.heading}>{actionChainTemplateName}</h1>
          </div>
          <div className={styles.topActionBar}>
            <GenericButton onClick={openModal}>
              {t("actionchain_new_action")}
            </GenericButton>
            <GenericButton
              onClick={() => {
                const entryPointItem = action.find(
                  (item) => item.actionChainItem.is_entrypoint === true
                );
                const isValid = entryPointItem
                  ? action.some((item) =>
                      item.actionChainItem.previous_items_and?.some(
                        (prevItem: { id: string }) =>
                          prevItem.id === entryPointItem.id
                      )
                    )
                  : false;

                if (!isValid) {
                  setError(t("actionchain_startpoint_no_connection"));
                  return;
                }
                batchSubmitOfActionChainItems(
                  action,
                  removeDeletedNodesFromState,
                  setRefreshtrigger,
                  setError
                );
              }}
            >
              {t("save")}
            </GenericButton>
          </div>
          <ReactFlow
            nodes={nodesWithDeleteHandler}
            nodeTypes={nodeTypes}
            edges={connectionWithDeleteHandler}
            edgeTypes={edgeTypes}
            onNodesChange={onActionChange}
            onEdgesChange={onConnectionChange}
            onConnect={onAddConnection}
            snapToGrid={true}
            snapGrid={[15, 15]}
            fitView
          >
            <Background variant={BackgroundVariant.Lines} gap={15} size={1} />
            <Controls />
            <MiniMap />
          </ReactFlow>
        </div>
        <Dialog open={actionNameModalOpen} fullWidth>
          <DialogTitle textAlign="center">{t("create")}</DialogTitle>

          <DialogContent sx={{ overflowY: "visible", width: "100%" }}>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(handleCreateAndEditNode)}
                noValidate
              >
                <ActionChainModalContent availableActions={actionOptions} />
                <DialogActions
                  sx={{ p: "0.75rem", justifyContent: "space-between" }}
                >
                  <GenericButton variant="outlined" onClick={closeModal}>
                    {t("cancel")}{" "}
                  </GenericButton>
                  <GenericButton type="submit">
                    {modalMode === "create" ? t("create") : t("save")}
                  </GenericButton>
                </DialogActions>
              </form>
            </FormProvider>
          </DialogContent>
        </Dialog>
        {error && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
      </ReactFlowProvider>
    </>
  );
};

export default ActionChainCreateAndEdit;
// _____________________________________________________________________________________________
function getActionChainTemplateItemsById(
  actionChainTemplateId: string,
  actionState: any,
  setAction: any,
  setConnection: any,
  setLastActionNodeY: any,
  setError: any
) {
  const additionnal: any = {};
  additionnal["action_chain_template"] = actionChainTemplateId;
  api.genericApiRequest({
    method: "get",
    entity: "actionChainItemTemplate",
    parametersToRender: { additionalUrlParameters: additionnal },
    successHandler: (res: any) => {
      const data = res.data.results;
      if (!res.data.results || res.data.results.length === 0) {
        return;
      }
      const tempActionStateData = transfromResponseToActionState(data);
      const tempConnectionStateData = transformResponseToConnectionState(data);

      const maxY = tempActionStateData?.reduce((max: any, item: any) => {
        const currentY = item.actionChainItem.metadata.position.y;
        return currentY > max ? currentY : max;
      }, -Infinity);

      setAction(tempActionStateData);
      setConnection(tempConnectionStateData);
      setLastActionNodeY(maxY);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}

async function batchSubmitOfActionChainItems(
  items: any,
  removeDeletedNodesFromState: any,
  setRefreshtrigger: any,
  setError: (error: any) => void
) {
  if (!Array.isArray(items)) {
    return;
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const itemsToPut = items.filter(
    (item: any) => item.actionChainItem.metadata.submitMethod === "put"
  );
  const itemsToPost = items.filter(
    (item: any) => item.actionChainItem.metadata.submitMethod === "post"
  );
  const itemsToDelete = items.filter(
    (item: any) => item.actionChainItem.metadata.submitMethod === "delete"
  );

  const preparedItemsToPost = itemsToPost.map((item: any) => {
    const actionChainItem = { ...item.actionChainItem };
    delete actionChainItem.previous_items_and;
    return {
      ...actionChainItem,
      id: item.id,
      metadata: {
        ...actionChainItem.metadata,
        position: item.position,
      },
    };
  });

  const preparedItemsToPut = itemsToPut.map((item: any) => ({
    ...item.actionChainItem,
    previous_items_and:
      item.actionChainItem.previous_items_and?.map((obj: any) => obj.id) || [],

    id: item.id,
    metadata: {
      ...item.actionChainItem.metadata,
      position: item.position,
    },
  }));

  const preparedItemsToDelete = itemsToDelete.map((item: any) => ({
    id: item.id,
  }));
  const postRequest = () =>
    new Promise((resolve) => {
      api.genericApiRequest({
        method: "post",
        entity: "actionChainItemTemplate",
        submitData: preparedItemsToPost,
        config: config,
        successHandler: (res: any) =>
          resolve({ success: true, data: res.data }),
        failHandler: (error: any) => resolve({ success: false, error }),
      });
    });
  const putRequest = (submitData: any) =>
    new Promise((resolve) => {
      api.genericApiRequest({
        method: "put",
        entity: "actionChainItemTemplate",
        submitData: submitData,
        config: config,
        successHandler: (res: any) =>
          resolve({ success: true, data: res.data }),
        failHandler: (error: any) => resolve({ success: false, error }),
      });
    });
  const deleteRequest = (submitData: any) =>
    new Promise((resolve) => {
      api.genericApiRequest({
        method: "delete",
        entity: "actionChainItemTemplate",
        entityId: submitData,
        successHandler: (res: any) =>
          resolve({ success: true, data: res.data }),
        failHandler: (error: any) => resolve({ success: false, error }),
      });
    });

  // ---DELETERequestHandling until BatchSupport was implemented--
  const processDeletions = async (itemsToDelete: any[]) => {
    const deletedIds: any[] = [];
    for (const item of itemsToDelete) {
      const deleteResult: any = await deleteRequest(item.id);
      if (deleteResult.success) {
        deletedIds.push(item.id);
      }
    }
    return deletedIds;
  };

  try {
    // ---POSTRequestHandling---
    const postResult: any =
      preparedItemsToPost.length > 0 ? await postRequest() : null;

    if (postResult && !postResult.success) {
      setError(postResult.error);
      return;
    }
    // ---DELETERequesthandling--
    const deletedNodeIds = await processDeletions(preparedItemsToDelete);

    if (deletedNodeIds.length > 0) {
      removeDeletedNodesFromState(deletedNodeIds);
    }

    // ---sanatize PUTItems after DELETE---
    const sanitizedItemsToPut = preparedItemsToPut.map((item: any) => ({
      ...item,
      previous_items_and: item.previous_items_and.filter(
        (id: any) => !deletedNodeIds.includes(id)
      ),
    }));

    const finalPutItems = [
      ...sanitizedItemsToPut,
      ...itemsToPost
        .filter(
          (item: any) => item.actionChainItem.previous_items_and?.length > 0
        )
        .map((item: any) => ({
          ...item.actionChainItem,
          previous_items_and:
            item.actionChainItem.previous_items_and?.map(
              (obj: any) => obj.id
            ) || [],
          id: item.id,
          metadata: {
            ...item.actionChainItem.metadata,
            position: item.position,
          },
        })),
    ];

    if (finalPutItems.length > 0) {
      const putResult: any = await putRequest(finalPutItems);

      if (putResult && !putResult.success) {
        setError(putResult.error);
      }
    }
    setRefreshtrigger((prev: any) => !prev);
  } catch (error) {
    console.error("ERROR", error);
  }
}
function getActionChain(
  actionChainId: string,
  handleSetActionChainTemplateName: any,
  setError: any
) {
  api.genericApiRequest({
    method: "get",
    entity: "actionChainTemplate",
    entityId: actionChainId,

    successHandler: (res: any) => {
      handleSetActionChainTemplateName(res.data.name);
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}

export function transfromResponseToActionState(data: any) {
  let currentY = 0;
  return data.map((dataItem: any) => {
    const defaultX = 400;
    const defaultY = currentY;

    currentY += 150;

    return {
      id: dataItem.id,
      position: {
        x: dataItem?.metadata?.position?.x ?? defaultX,
        y: dataItem?.metadata?.position?.y ?? defaultY,
      },

      data: {
        label: dataItem.name,
        action: dataItem.action,
        additional_data: dataItem.additional_data,
        id: dataItem.id,
        is_entrypoint: dataItem.is_entrypoint,
      },
      actionChainItem: {
        name: dataItem.name,
        action: dataItem.action,
        is_entrypoint: dataItem.is_entrypoint,
        additional_data: dataItem.additional_data,
        action_chain_template: dataItem.action_chain_template.id,
        metadata: {
          ...dataItem.metadata,
          submitMethod: "put",
          position: {
            x: dataItem?.metadata?.position?.x ?? defaultX,
            y: dataItem?.metadata?.position?.y ?? defaultY,
          },
        },

        submitMethod: "put",
        previous_items_and: dataItem.previous_items_and,
        previous_items_or: dataItem.previous_items_or,
      },
      measured: {
        width: 200,
        height: 46,
      },
    };
  });
}

export function transformResponseToConnectionState(response: any) {
  return response.reduce((edges: any, item: any) => {
    const targetId = item.id;
    const targetData = {
      action: item.action,
      additional_data: item.additional_data,
      id: item.id,
      label: item.name,
      status: "",
    };

    item.previous_items_and.forEach((previousItem: any) => {
      const sourceId = previousItem.id;

      const sourceItem = response.find((res: any) => res.id === sourceId);

      const sourceData = sourceItem
        ? {
            action: sourceItem.action,
            additional_data: sourceItem.additional_data,
            id: sourceItem.id,
            label: sourceItem.name,
          }
        : {};

      edges.push({
        target: targetId,
        source: sourceId,
        id: `xy-edge__${sourceId}-${targetId}`,
        type: "custom",
        markerEnd: { type: "arrowClosed" },
        data: {
          sourceData,
          targetData,
        },
      });
    });

    return edges;
  }, []);
}
