import { useEffect, useRef, useState } from "react";
import GeneralListItem from "../general/generaListItem";
import { IAppointment } from "../../types/Entities";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Grid } from "@mui/material";
import { DotLoader } from "react-spinners";
import { api } from "../../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setAppointmentDashboard } from "../state/appointment/appointmentDashboardSlice";
import { RootState } from "../state/store";
import dayjs from "dayjs";
import { t } from "i18next";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import DropDown from "../forms/inputs/dropDown";
import { setAppointmentDashboardSelectedCalendarDate } from "../state/appointmentDashboard/selectedCalendarDaySlice";
import { setAppointmentDashboardCombinedFilter } from "../state/appointmentDashboard/combinedFilterSlice";
import {
  getAppointmentDetails,
  statusArrayForAppDashboard,
} from "../../helper/statusOptions";
import styles from "./appointmentList.module.css";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import GenericButton from "../forms/inputs/button/genericButton";

function AppointmentList(props: any) {
  const dispatch = useDispatch();
  const combinedFilter = useSelector(
    (state: RootState) => state.appointmentDashboardCombinedFilter
  );
  const persistedSelectedDate = useSelector(
    (state: RootState) => state.appointmentDashboard
  );
  const selectedCalendarDay = useSelector(
    (state: RootState) => state.appointmentDashboardSelectedCalendarDate
  );
  const [appointmentDays, setAppointmentDays]: any = useState<string[]>([]);
  const [daysWithAppointments, setDaysWithAppointments]: any = useState({});
  const [selectedDay, setSelectedDay] = useState<any>(
    new Date(
      selectedCalendarDay.value || persistedSelectedDate.selectedDashboardDate
    )
  );
  const [appointmentsForSelectedDay, setAppointmentsForSelectedDay]: any =
    useState<IAppointment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeStarteDate, setActiveStartDate] = useState<Date>();
  const [error, setError] = useState<any>();
  // eslint-disable-next-line
  const [subjectOptions, setSubjectOptions] = useState<any>([]);
  const [statusOptions, setStatusOptions] = useState<any>([]);
  const [mainCategoryOptions, setMaincategoryOptions] = useState<any>([]);
  const [executedByOptions, setExecutedByOptions] = useState<any>([]);
  const [filters, setFilters] = useState<{
    status: string;
    mainCategory: string;
    executedBy: string;
    subject: { value: string; label: string };
  }>({
    status: "",
    mainCategory: "",
    executedBy: "",
    subject: { value: "", label: "" },
  });

  const today = dayjs(new Date());
  const prevCombinedFilterRef = useRef(combinedFilter);
  const prevSelectedDayRef = useRef<Date | null>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    setIsLoading(true);

    getMainCategoryOptions(setMaincategoryOptions, setError);
    getActiveUsersForExecutedByOptions(setExecutedByOptions, setError);
    getTranslatedStatusOptions(setStatusOptions);
    reloadAppointmentDays(dayjs().month(), dayjs().year(), () =>
      setIsLoading(false)
    );
  }, []);

  useEffect(() => {
    const { status, mainCategory, executedBy, subject } = combinedFilter;
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      status: status || "",
      mainCategory: mainCategory || "",
      executedBy: executedBy || "",
      subject: subject.value === "" ? [] : subject,
    }));
  }, [combinedFilter]);

  useEffect(() => {
    const { status, mainCategory, executedBy, subject } = combinedFilter;
    if (status || mainCategory || executedBy || subject) {
      reloadAppointmentDays(
        dayjs(activeStarteDate).format("M"),
        dayjs(activeStarteDate).format("YYYY"),
        () => {
          setIsLoading(false);
        },
        true
      );
    }
  }, [combinedFilter, activeStarteDate]);

  useEffect(() => {
    const daysWithAppointments: any = {};

    for (const key in appointmentDays) {
      const dateOfAppointment = new Date(appointmentDays[key]);
      const appointmentYear = dateOfAppointment.getFullYear();
      const appointmentMonth = dateOfAppointment.getMonth();
      const appointmentDay = dateOfAppointment.getDate();

      if (
        !Object.getOwnPropertyDescriptor(daysWithAppointments, appointmentYear)
      ) {
        daysWithAppointments[appointmentYear] = {};
      }

      if (
        !Object.getOwnPropertyDescriptor(
          daysWithAppointments[appointmentYear],
          appointmentMonth
        )
      ) {
        daysWithAppointments[appointmentYear][appointmentMonth] = {};
      }

      daysWithAppointments[appointmentYear][appointmentMonth][appointmentDay] =
        true;
    }

    setDaysWithAppointments(daysWithAppointments);
  }, [appointmentDays]);

  useEffect(() => {
    const applyFilter = () => {
      setIsLoading(true);
      selectedDay.setHours(12); // quick hack, because of timesaving it will be otherwise the day before
      const dateStringForSelectedDay = selectedDay.toISOString().split("T")[0];

      const additionalUrlParameters: any = {};
      const additionalRouteParts: any = {};

      additionalUrlParameters["date"] = dateStringForSelectedDay;

      const { status, mainCategory, executedBy, subject }: any = combinedFilter;

      const statusMappings: any = {
        in_progress: [
          "in_progress",
          "post_processing_required",
          "scheduled",
          "approved",
        ],
        in_review: [
          "in_review",
          "waiting_for_review",
          "review_preperation_required",
        ],
      };

      if ((status !== "" && status) || status.length !== 0) {
        additionalUrlParameters["status__in"] = statusMappings[status] || [
          status,
        ];
      }
      if (mainCategory !== "" && mainCategory) {
        additionalUrlParameters["categoryexamination__category__in"] =
          mainCategory;
      }
      if (executedBy !== "" && executedBy) {
        additionalUrlParameters["executed_by"] = executedBy;
      }

      if (Array.isArray(subject)) {
        if (
          subject.length > 0 &&
          subject[0]?.value != null &&
          subject[0]?.value != ""
        ) {
          additionalUrlParameters["subject_id"] = subject[0]?.value;
        }
      } else if (subject && subject?.value != null && subject?.value != "") {
        console.log("ZUWUWUWU", subject);
        additionalUrlParameters["subject_id"] = subject.value;
      }
      additionalRouteParts["dashboard"] = "/";

      getAppointments(
        setAppointmentsForSelectedDay,
        additionalUrlParameters,
        additionalRouteParts,
        setError,
        setIsLoading
      );
    };

    if (isFirstRender.current) {
      applyFilter();
      isFirstRender.current = false;
    } else if (
      selectedDay !== prevSelectedDayRef.current ||
      prevCombinedFilterRef.current.status !== combinedFilter.status ||
      prevCombinedFilterRef.current.mainCategory !==
        combinedFilter.mainCategory ||
      prevCombinedFilterRef.current.executedBy !== combinedFilter.executedBy ||
      JSON.stringify(prevCombinedFilterRef.current.subject) !==
        JSON.stringify(combinedFilter.subject)
    ) {
      applyFilter();
    }

    prevCombinedFilterRef.current = combinedFilter;
    prevSelectedDayRef.current = selectedDay;
  }, [selectedDay, focus, combinedFilter]);

  const reloadAppointmentDays = (
    month: any,
    year: any,
    successCallback: any,
    shouldNotCorrectMonth?: boolean
  ) => {
    let correctMonth = month + 1;

    if (shouldNotCorrectMonth) {
      correctMonth = month;
    }

    const additionalUrlParameters: any = {};
    const additionalRouteParts: any = {};
    additionalRouteParts["fetch_available_appointments"] = "/";

    additionalUrlParameters["month"] = correctMonth;
    additionalUrlParameters["year"] = year;

    const { status, mainCategory, executedBy, subject }: any = combinedFilter;
    const statusMappings: any = {
      in_progress: [
        "in_progress",
        "post_processing_required",
        "scheduled",
        "approved",
      ],
      in_review: [
        "in_review",
        "waiting_for_review",
        "review_preperation_required",
      ],
    };

    if (status !== "") {
      additionalUrlParameters["status__in"] = statusMappings[status] || [
        status,
      ];
    }
    if (mainCategory !== "") {
      additionalUrlParameters["categoryexamination__category__in"] =
        mainCategory;
    }
    if (executedBy !== "") {
      additionalUrlParameters["executed_by"] = executedBy;
    }
    if (Array.isArray(subject)) {
      if (
        subject.length > 0 &&
        subject[0]?.value != null &&
        subject[0]?.value != ""
      ) {
        additionalUrlParameters["subject_id"] = subject[0]?.value;
      }
    } else if (subject && subject?.value != null && subject?.value != "") {
      additionalUrlParameters["subject_id"] = subject.value;
    }

    getDistinctDayAppointments(
      setAppointmentDays,
      successCallback,
      additionalRouteParts,
      additionalUrlParameters,
      setError
    );
  };

  //eslint-disable-next-line
  const handleMonthChange = ({ action, activeStartDate, value, view }: any) => {
    activeStartDate.setHours(12);
    setActiveStartDate(activeStartDate.setHours(12));

    reloadAppointmentDays(
      activeStartDate.getMonth(),
      activeStartDate.getFullYear(),
      () => {
        if (appointmentsForSelectedDay.length === 0) {
          setIsLoading(false);
        }
      },
      false
    );
  };
  //added a second case because of the different metricNaming in LiveSystem
  const getAppointmentAddress = (appointment: any) => {
    const buildingInformation = appointment?.building_information;

    if (
      buildingInformation.street_house &&
      buildingInformation.zip &&
      buildingInformation.city
    ) {
      return (
        buildingInformation.street_house +
        ", " +
        buildingInformation.zip +
        " " +
        buildingInformation.city
      );
    } else {
      return "-";
    }
  };

  const preRenderItemsForSelectedDay = () => {
    const itemsPerHour: any = {};
    const preRenderedItems = [];

    for (const key in appointmentsForSelectedDay) {
      const appointment = appointmentsForSelectedDay[key];
      const dateObject = new Date(appointment.datetime);
      const appointmentExaminations = [];
      const appointmentAddress = getAppointmentAddress(appointment);

      for (const key in appointment.examination_name_set) {
        appointmentExaminations.push(appointment.examination_name_set[key]);
      }
      let mainCategoryName = appointment?.maincategory_set?.[0]?.name;
      if (mainCategoryName === undefined) {
        mainCategoryName = "-";
      }
      const { backgroundColor, appointmentStatus } = getAppointmentDetails(
        appointment.status
      );

      const item = (
        <GeneralListItem
          key={appointment.id}
          onClick={() => {
            props.onClickHandler(appointment.id);
          }}
          onClickElement="wrapper"
          wrapperClassName={styles.generalListItemWrapper}
          itemBackgroundColor={backgroundColor}
          hideButton={true}
        >
          <table className={styles.tableStyle} border={0}>
            <colgroup>
              <col className={styles.col20} />
              <col className={styles.col40} />
              <col className={styles.col20} />
            </colgroup>
            <tbody>
              <tr>
                <td className={styles.cellStyle}>
                  <strong>
                    {dateObject.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    {t("time_indicator")}
                  </strong>
                </td>
                <td className={styles.cellStyle}>
                  {t("company")}: <strong>{appointment.company_name}</strong>
                </td>
                <td className={styles.cellStyle}>
                  {t("team")}: <strong>{appointment.team.name}</strong>
                </td>
              </tr>
              <tr>
                <td rowSpan={2} className={styles.cellStyle}>
                  {appointmentExaminations?.join(", ")}
                </td>
                <td className={styles.cellStyle}>
                  {t("location")}: <strong>{appointmentAddress}</strong>
                </td>
                <td className={styles.cellStyle}>
                  {t("main_category")}: <strong>{mainCategoryName}</strong>
                </td>
              </tr>
              <tr>
                <td className={styles.cellStyle}>
                  {t("building_subject")}:{" "}
                  <strong>{appointment.subject.name}</strong>
                </td>
                <td className={styles.cellStyle}>
                  {t("status")}: <strong>{appointmentStatus}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </GeneralListItem>
      );

      const appointmentHour = dateObject.getHours();

      if (!Object.getOwnPropertyDescriptor(itemsPerHour, appointmentHour)) {
        itemsPerHour[appointmentHour] = [];
      }

      itemsPerHour[appointmentHour].push(item);
    }

    for (const i in Object.keys(itemsPerHour)) {
      const key = Object.keys(itemsPerHour)[i];
      preRenderedItems.push(
        <h2 key={key + "_timeslot_for_day"}>{key}:00 Uhr</h2>
      );
      for (const j in itemsPerHour[key]) {
        const appointment = itemsPerHour[key][j];
        preRenderedItems.push(appointment);
      }
    }

    return preRenderedItems;
  };

  const calendarTileDisabled = ({ date, view }: any) => {
    if (view === "month") {
      if (
        daysWithAppointments[date.getFullYear()] &&
        daysWithAppointments[date.getFullYear()][date.getMonth()] &&
        daysWithAppointments[date.getFullYear()][date.getMonth()][
          date.getDate()
        ]
      ) {
        return false;
      }
    }

    return true;
  };

  const onChange = (selectedDay: any, focus?: boolean) => {
    setSelectedDay(selectedDay);
    dispatch(
      setAppointmentDashboard({
        selectedDashboardDate: selectedDay.toISOString(),
      })
    );
    if (focus) {
      setActiveStartDate(undefined);
    }
    setAppointmentsForSelectedDay([]);
    dispatch(
      setAppointmentDashboardSelectedCalendarDate(selectedDay.toISOString())
    );
  };

  //handleDropDownFilterLogic - set to CombinedState and dispatch to CombinedGlobalState
  const handleFilter = (name: any, value: any) => {
    if (name === "subject") {
      if (
        value &&
        typeof value === "object" &&
        value.value !== undefined &&
        value.label !== undefined
      ) {
        setFilters((prev: any) => ({
          ...prev,
          [name]: { value: value.value, label: value.label },
        }));
        dispatch(
          setAppointmentDashboardCombinedFilter({
            name,
            value: { value: value.value, label: value.label },
          })
        );
      }
    } else {
      if (value !== undefined) {
        setFilters((prev: any) => ({ ...prev, [name]: value }));
        dispatch(setAppointmentDashboardCombinedFilter({ name, value }));
      }
    }
  };
  //handleDropDownFilterLogic - dispatch/clear entry of CombinedGlobal & localState
  const handleClearDropdown = (name: any) => {
    setFilters((prev: any) => ({
      ...prev,
      [name]: name === "subject" ? { value: "", label: "" } : "",
    }));
    dispatch(
      setAppointmentDashboardCombinedFilter({
        name,
        value: name === "subject" ? { value: "", label: "" } : "",
      })
    );
  };

  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <h1>
              {t("appointment_dashboard")} (
              {dayjs(selectedDay).format("DD.MM.YYYY")})
            </h1>
          </Grid>
          <Grid item xs={9}>
            {preRenderItemsForSelectedDay()}
          </Grid>
          <Grid item xs={3} className={styles.stickyContainer}>
            <Calendar
              onChange={(selectedDay) => onChange(selectedDay)}
              minDetail={"month"}
              onActiveStartDateChange={handleMonthChange}
              tileDisabled={calendarTileDisabled}
              value={selectedDay}
            />
            <Grid container item className={styles.dateButtonContainer}>
              <Grid item>
                <GenericButton
                  onClick={() => onChange(new Date(), true)}
                  variant="outlined"
                  disabled={
                    today.format("DD/MM/YYYY") ===
                      dayjs(selectedDay).format("DD/MM/YYYY") &&
                    today.format("DD/MM/YYYY") ===
                      dayjs(selectedDay).format("DD/MM/YYYY") &&
                    dayjs(activeStarteDate).format("MM") === today.format("MM")
                  }
                >
                  {t("date_today")}
                </GenericButton>
              </Grid>
              <Grid item>
                <GenericButton
                  variant="outlined"
                  onClick={() => onChange(new Date(selectedDay), true)}
                  disabled={
                    dayjs(activeStarteDate).format("MM") ===
                    dayjs(selectedDay).format("MM")
                  }
                >
                  {t("date_selected")}
                </GenericButton>
              </Grid>
            </Grid>
            <DropDown
              options={mainCategoryOptions}
              onChange={(event: any) =>
                handleFilter("mainCategory", event?.value)
              }
              name={t("main_category")}
              label={t("main_category")}
              isClearable={true}
              resetInput={() => handleClearDropdown("mainCategory")}
              defaultValue={filters.mainCategory}
            />
            <DropDown
              options={statusOptions}
              onChange={(event: any) => handleFilter("status", event?.value)}
              name={t("status")}
              label={t("status")}
              isClearable={true}
              resetInput={() => handleClearDropdown("status")}
              defaultValue={filters.status}
            />

            <DropDown
              options={executedByOptions}
              onChange={(event: any) =>
                handleFilter("executedBy", event?.value)
              }
              name={t("executed_by")}
              label={t("executed_by")}
              isClearable={true}
              resetInput={() => handleClearDropdown("executedBy")}
              defaultValue={filters.executedBy}
            />
            <DropDownSubjectSearch
              options={subjectOptions}
              subdomain={"admin"}
              onChange={(event: any) => handleFilter("subject", event)}
              name={t("subject")}
              customLabel={t("subject")}
              isClearable={true}
              resetInput={() => handleClearDropdown("subject")}
              useGlobalState={false}
              preExistingValue={filters.subject}
            />
          </Grid>
        </Grid>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
export default AppointmentList;

function getMainCategoryOptions(setMaincategoryOptions: any, setError: any) {
  api.genericApiRequest({
    method: "get",
    entity: "mainCategory",
    successHandler: (res: any) => {
      const data = res.data.results;
      setMaincategoryOptions(
        data.map((cat: any) => ({ value: cat.id, label: cat.name }))
      );
    },
    failHandler: (error: any) => setError(error),
  });
}

function getActiveUsersForExecutedByOptions(
  setExecutedByOptions: any,
  setError: any
) {
  const additionalUserParameters: any = {};
  additionalUserParameters["impersonate_subject"] =
    "11111111-1111-1111-1111-444444444444";
  additionalUserParameters["is_active"] = "true";

  api.genericApiRequest({
    entity: "user",
    method: "get",
    parametersToRender: {
      additionalUrlParameters: additionalUserParameters,
      depth: "0",
    },

    successHandler: (res: any) => {
      const data = res.data.results;
      const tempUserArray = data.map((user: any) => ({
        value: user.id,
        label: user.last_name + ", " + user.first_name,
      }));
      setExecutedByOptions(
        tempUserArray.sort((a: any, b: any) => a.label.localeCompare(b.label))
      );
    },
    failHandler: (error: any) => {
      setError(error);
    },
  });
}

function getTranslatedStatusOptions(setStatusOptions: any) {
  const translatedStatusArray = statusArrayForAppDashboard.map((status) => ({
    ...status,
    label: Array.isArray(status.label)
      ? status.label.map((item) => t(item))
      : t(status.label),
  }));
  setStatusOptions(translatedStatusArray);
}

function getAppointments(
  setAppointmentsForSelectedDay: any,
  additionalUrlParameters: any,
  additionalRouteParts: any,
  setError: any,
  setIsLoading: any
) {
  api.genericApiRequest({
    entity: "appointment",
    method: "get",
    successHandler: (res: any) => {
      const data = res.data.results;
      setAppointmentsForSelectedDay(data);
      setIsLoading(false);
    },
    failHandler: (error: any) => {
      setError(error);
      setIsLoading(false);
    },
    additionalRouteParts: additionalRouteParts,
    parametersToRender: {
      limit: "150",
      offset: "0",
      depth: "1",
      additionalUrlParameters: additionalUrlParameters,
    },
  });
}

function getDistinctDayAppointments(
  setAppointmentDays: any,
  successCallback: any,
  additionalRouteParts: any,
  additionalUrlParameters: any,
  setError: any
) {
  api.genericApiRequest({
    entity: "appointment",
    method: "get",

    successHandler: (res: any) => {
      setAppointmentDays(res.data.distinct_days);
      successCallback();
    },
    failHandler: (error: any) => {
      setError(error);
    },
    additionalRouteParts: additionalRouteParts,
    parametersToRender: {
      additionalUrlParameters: additionalUrlParameters,
      depth: "0",
    },
  });
}
