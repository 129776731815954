import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import GenericControllerWithInput from "../forms/inputsWithController/GenericController";
import { t } from "i18next";
import DropDown from "../forms/inputs/dropDown";
import { InputWithRef } from "../forms/inputsWithRef/inputWithRef";
import { TextAreaWithRef } from "../forms/inputsWithRef/textAreaWithRef";

type ActionChainModalContentProps = {
  availableActions: any;
};

const ActionChainModalContent: FC<ActionChainModalContentProps> = ({
  availableActions,
}) => {
  const { control } = useFormContext();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GenericControllerWithInput
        control={control}
        name="name"
        rules={{
          required: {
            value: true,
            message: t("field_required"),
          },
        }}
        renderInput={({ onChange, ref, value }) => (
          <InputWithRef
            name="name"
            onChange={onChange}
            label={t("name")}
            ref={ref}
            value={value}
            mandatory={true}
          />
        )}
      />
      <GenericControllerWithInput
        control={control}
        name="action"
        rules={{
          required: {
            value: true,
            message: t("field_required"),
          },
        }}
        renderInput={({ onChange, value }) => (
          <DropDown
            options={availableActions}
            onChange={onChange}
            name="action"
            label={t("actionchain_available_actions")}
            defaultValue={value}
            mandatory={true}
          />
        )}
      />
      <GenericControllerWithInput
        control={control}
        name="additional_parameters"
        renderInput={({ onChange, ref, value }) => (
          <TextAreaWithRef
            label={t("actionchain_additional_parameters")}
            name="additional_parameters"
            onChange={onChange}
            ref={ref}
            value={value}
          />
        )}
      />
    </div>
  );
};

export default ActionChainModalContent;
