import { t } from "i18next";
import GenericCrudTable from "../tables/genericCrudTable";
import { Department } from "./administration";
import { Chip } from "@mui/material";
import styles from "./departmentTable.module.css";

interface Props {
  companySubjectId?: string; //only present if Admin
  companyId?: string;
  uploadSuccessful?: boolean;
  handleDepartmentForFilter: (row: any) => any;
  department?: Department[];
  handleDeleteDepartment?: () => void;
  hasDepartments?: any;
}

export default function DepartmentTable(props: Props) {
  return (
    <GenericCrudTable
      allowColumnFiltering={true}
      showColumnFilterbyDefault={true}
      entity={"department"}
      columns={[
        {
          header: "ID",
          accessorKey: "id",
          id: "id",
          enableHiding: !props.companySubjectId ? false : true,
          visibleInShowHideMenu: !props.companySubjectId ? false : true,
        },
        {
          header: t("name"),
          accessorKey: "name",
          id: "name",
          enableForCreate: true,
          required: true,
          errorMessage: t("field_required"),
          validate: true,
          rule: /^[a-zA-ZÄäÖöÜüß0-9 _&()+/-]+$/,
          errorMessageValidation: t("invalid_characters"),
          autoComplete: "off",
          Cell: ({ cell, row }: any) => {
            return (
              <>
                {props.department &&
                props.department?.length !== 0 &&
                props.department[0].departmentId === row.original.id ? (
                  <Chip
                    label={props.department?.[0].departmentName}
                    onDelete={props.handleDeleteDepartment}
                    className={styles.chip}
                    classes={{ deleteIcon: styles.deleteIcon }}
                  />
                ) : (
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => props.handleDepartmentForFilter?.(row)}
                  >
                    {cell.getValue()}
                  </span>
                )}
              </>
            );
          },
        },
        {
          header: t("customer"),
          accessorKey: "user_count",
          id: "user_count",
          size: 40,
          enableColumnFilter: false,
        },
        {
          header: t("building_department"),
          accessorKey: "building",
          id: "building",
          customColumnFilterId: "building__name",
          enableForCreate: true,
          enableColumnFilter: false,

          showAddressForBuildingSelect: true,
          entity: "building",
          accessorFn: (row: any) => {
            if (
              row.building &&
              (row.main_building !== null || row.main_building !== undefined)
            ) {
              const building = row.building;
              return (
                <>
                  <span>{building.name}</span>
                </>
              );
            }
          },
          size: 40,
        },
      ]}
      companySubjectId={props.companySubjectId}
      companyId={props.companyId}
      uploadSuccessful={props.uploadSuccessful}
      department={props.department}
      hasDepartments={props.hasDepartments}
      // additionalUrlParameter="include_user_count=true"
      additionalUrlParameter={{ include_user_count: true }}
    />
  );
}
